import people from '../assets/images/people.png';
import person from '../assets/images/person1.png';

export const review = [
        {
            text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ac mauris eget massa gravida fermentum.',
            img: people,
            title: 'Sarah Williams',
            subtitle: 'Student, GHI College',
        },
        {
            text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ac mauris eget massa gravida fermentum.',
            img: person,
            title: 'Jane Smith',
            subtitle: 'Student, XYZ College',
        },
        {
            text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ac mauris eget massa gravida fermentum.',
            img: people,
            title: 'Micheal Brown',
            subtitle: 'Student, GHI College',
        },
        {
            text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ac mauris eget massa gravida fermentum.',
            img: person,
            title: 'Amelie Ward',
            subtitle: 'Student, XYZ College',
        },
        {
            text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ac mauris eget massa gravida fermentum.',
            img: people,
            title: 'Jane Smith',
            subtitle: 'Student, GHI College',
        },
    ];