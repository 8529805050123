import React from 'react';
import { Box, Flex, Icon, VStack, Text, Image, IconButton } from '@chakra-ui/react';
import { ArrowForwardIcon, ArrowBackIcon, StarIcon } from '@chakra-ui/icons';


import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Team from '../../pages/services/components/Team';
import { review } from '../../data/review';


const Reviews = () => {
  const customPaging = (i: number) => (
    <Box
      borderRadius="50%"
      width="8px"
      height="8px"
      maxWidth="100%"
      position="absolute"
      left={{ base: '-105px', lg: '-580px' }}
      bgColor="#46BDFF"
      display="flex"
      justifyContent="flex-start"
      opacity={i === 0 ? 1 : 0.5}
      _hover={{ opacity: 1 }}
    />
  );

  const NextArrow = (props: any) => (
    <IconButton
      icon={<ArrowForwardIcon />}
      bgColor="#46BDFF30"
      color="#46BDFF"
      borderRadius="50%"
      size="lg"
      position="absolute"
      top="100%"
      right="0"
      transform="translateY(-50%)"
      zIndex="1"
      onClick={props.onClick}
      aria-label={''}
    />
  );

  const PrevArrow = (props: any) => (
    <IconButton
      icon={<ArrowBackIcon />}
      bgColor="#46BDFF30"
      color="#46BDFF"
      borderRadius="50%"
      size="lg"
      position="absolute"
      top="100%"
      left={{ base: '71%', lg: '91%' }}
      transform="translateY(-50%)"
      zIndex="1"
      onClick={props.onClick}
      aria-label={''}
    />
  );
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2, // Number of reviews to show on desktop
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1, // Number of reviews to show on mobile
          slidesToScroll: 1,
        },
      },
    ],
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    customPaging: customPaging, // Custom styling for dots
  };

  return (
    <Box w="100%" py={{ base: '2rem', md: '4rem' }}>
      <Slider {...settings}>
        {review.map((item, index) => (
          <VStack align="start" pb="4rem" gap="2rem" key={index}>
            <Flex align="center">
              {[...Array(5)].map((_, index) => {
                return (
                  <Icon key={index} as={StarIcon} boxSize={4} color="#FDD13C" cursor="pointer" />
                );
              })}
            </Flex>
            <VStack gap="1rem" align="start" mt="1rem">
              <Text w={{ base: '100%', md: '80%' }} fontSize={{ base: '1rem', md: '1.1rem' }}>
                {item.text}
              </Text>
              <Flex align="center" gap="1rem">
                <Image w="56px" h="56px" borderRadius="50%" src={item.img} alt="image" />
                <Box>
                  <Text fontWeight="bold">{item.title}</Text>
                  <Text>{item.subtitle}</Text>
                </Box>
              </Flex>
            </VStack>
          </VStack>
        ))}
      </Slider>
      <Team />
    </Box>
  );
};

export default Reviews;
