import { Box, VStack, Flex, Text, Heading, Button, HStack, Image } from '@chakra-ui/react';
import React from 'react';
import unlock from '../../assets/images/unlock.png';
import computer from '../../assets/images/computer.png';
import coffee from '../../assets/images/coffee.png';
import dynamic from '../../assets/icons/dynamic.svg';
import easy from '../../assets/icons/easy.svg';
import { Routes } from '../../constant';
import { useNavigate } from 'react-router-dom';
import Recommendation from '../discovery/components/Recommendation';
import Banner3 from '../discovery/components/banner3';
import Career from '../discovery/components/Career';
import GetInTouch from '../services/components/GetInTouch';
import Footer from '../../components/footer';
const careerData = [
  {
    title: 'Create Your Learning Plan Today',
    subtitle: 'Get personalized career advice and a customized learning plan',
    button1: 'Start',
    button2: 'Learn More',
  },
];

const Programs = () => {
  const navigate = useNavigate();

  return (
    <Box w="100%">
      <VStack>
        <Flex
          background={`url(${unlock})`}
          backgroundSize="cover"
          width="100%"
          height="490px"
          justify="center"
          align="center"
          px={{ base: '1.5rem', md: '2rem', lg: '3rem' }}
        >
          <VStack width={{ base: '100%', md: '50%' }}>
            <Flex
              align="center"
              justify="center"
              borderRadius="7px"
              bg="#01F0B65C"
              width="118px"
              height="31px"
            >
              <Text color="#01F0B6">Empower</Text>
            </Flex>
            <VStack gap="1rem">
              <Heading color="white" lineHeight="1.3" fontSize={{ base: '1.8rem', md: '2.6rem' }}>
                Unlock Your Potential
              </Heading>
              <Text color="white" fontSize={{ base: '1rem', md: '1.2rem' }} textAlign="center">
                Discover your passion, set goals, and create a personalized learning plan.
              </Text>
            </VStack>
            <HStack mt="1.5rem">
              <Button
                as="a"
                bg="#46BDFF"
                cursor="pointer"
                _hover={{
                  bgGradient: 'linear(to-r, #46BDFF, pink.100)',
                  color: 'white',
                }}
                fontWeight="400"
                border="1px solid #46BDFF"
                color="white"
                aria-label="Get started"
                fontSize={{ base: '14px', md: '16px' }}
              >
                Get Started{' '}
              </Button>
              <Button
                border="1px solid #46BDFF"
                bg="none"
                color="#46BDFF"
                fontWeight="400"
                _hover={{
                  bg: '#46beff19',
                }}
                aria-label="learn more"
                fontSize={{ base: '14px', md: '16px' }}
              >
                Learn More
              </Button>
            </HStack>
          </VStack>
        </Flex>
      </VStack>
      <Box px={{ base: '1.5rem', md: '2rem', lg: '3rem' }}>
        <Flex
          w="100%"
          flexDir={{ base: 'column', md: 'row' }}
          justify="space-between"
          align="center"
          gap={{ base: '1rem' }}
          py={{ base: '2rem', md: '4.5rem' }}
        >
          <VStack align="start" gap="1rem" width={{ base: '100%', md: '50%' }}>
            <Heading lineHeight="1.2" fontSize={{ base: '1.8rem', md: '2.6rem' }}>
              Creating Personalized Learning Plans with AI Technology{' '}
            </Heading>
            <Text>
              Our AI technology analyzes student&apos;s goals, interests, and strengths to build
              customized learning plans that maximize their potential.
            </Text>
          </VStack>
          <Box>
            <Image height={{ md: '60%' }} src={computer} alt="system" />
          </Box>
        </Flex>
        <Flex
          w="100%"
          align="center"
          justify={{ lg: 'space-between' }}
          flexDir={{ base: 'column', md: 'column', lg: 'row' }}
          py={{ base: '2rem', md: '4rem', lg: '6rem' }}
        >
          <VStack align="start" gap="2.5rem" width={{ base: '100%', md: '100%', lg: '50%' }}>
            <Flex
              align="center"
              justify="center"
              borderRadius="7px"
              bg="#FE085B1A"
              width="118px"
              height="31px"
            >
              <Text color="#FE0459">Empower</Text>
            </Flex>
            <Heading lineHeight="1.3" fontSize={{ base: '1.8rem', md: '2.2rem' }}>
              Unlock Your Full Potential with a Personalized Learning Plan{' '}
            </Heading>
            <Text>
              Our tailored learning plans provide numerous benefits, including improved academic
              performance, focused learning, and better time management. By leveraging AI
              technology, we analyze your inputs and create a personalized guide that helps you
              navigate your educational journey.
            </Text>
            <Flex
              flexDir={{ base: 'column', md: 'column', lg: 'row' }}
              gap={{ base: '1rem', md: '1rem' }}
            >
              <VStack align="start">
                <Image src={dynamic} alt="" />
                <Text fontWeight="bold" fontSize={{ base: '1rem', md: '1.1rem' }}>
                  Improved Performance{' '}
                </Text>
                <Text>
                  Experience significant improvements in your academic performance with a
                  personalized learning plan.
                </Text>
              </VStack>
              <VStack align="start">
                <Image src={easy} alt="" />

                <Text fontWeight="bold" fontSize={{ base: '1rem', md: '1.1rem' }}>
                  Focused Learning{' '}
                </Text>
                <Text>
                  Stay on track and achieve your learning goals with a tailored plan that keeps you
                  focused.
                </Text>
              </VStack>
            </Flex>
            <Flex w="100%" gap="1rem" justify="start">
              <Button
                as="a"
                border="1px solid #46BDFF"
                fontWeight="400"
                color="#46BDFF"
                bg="white"
                _hover={{
                  bg: 'white',
                }}
                aria-label="sign up"
                onClick={() => navigate(Routes.ComingSoon)}
                fontSize={{ base: '14px', md: '16px' }}
              >
                Sign Up
              </Button>
              <Button
                fontWeight="400"
                color="#46BDFF"
                bg="white"
                _hover={{
                  bg: 'white',
                }}
                aria-label="learn more"
                fontSize={{ base: '14px', md: '16px' }}
              >
                Learn More &rarr;
              </Button>
            </Flex>
          </VStack>
          <Box mt={{ base: '1.5rem' }}>
            <Image src={coffee} alt="img" />
          </Box>
        </Flex>
        <Box py={{ base: '2rem', md: '4.5rem' }}>
          <Recommendation />
        </Box>
      </Box>
      <Box py={{ base: '2rem', md: '4.5rem' }}>
        <Banner3 />
      </Box>
      <VStack
        py={{ base: '2rem', md: '4rem' }}
        px={{ base: '1.5rem', md: '2rem', lg: '3rem' }}
        gap="1rem"
      >
        <Box
          px={{ base: '1.5rem', md: '2rem', lg: '3rem' }}
          w="100%"
          bg="#FDD13C"
          borderRadius="10px"
          py={{ base: '2rem', md: '4rem' }}
        >
          {careerData.map((data, index) => (
            <Career data={data} key={index} />
          ))}
        </Box>{' '}
        <GetInTouch />
      </VStack>
      <Footer />
    </Box>
  );
};

export default Programs;
