import { Box } from '@chakra-ui/react';
// import { Button, Flex, Heading, SimpleGrid, VStack, Text, Box } from '@chakra-ui/react';
import React from 'react';
// import { CardTeam } from '../../../components/card';
// import { teamData } from '../../../data';

const Team = () => {
  return (
    <Box>
      {/* <VStack align="start" py={{ base: '2rem', md: '4rem' }}>
        <Flex
          align="center"
          justify="center"
          borderRadius="7px"
          bg="#46BDFF2B"
          width="118px"
          height="31px"
        >
          <Text color="#46BDFF">Empowering</Text>
        </Flex>
        <Heading lineHeight="1.3" fontSize={{ base: '1.8rem', md: '2.2rem' }}>
          Our Team
        </Heading>
        <Text>Our team of experts and AI developers behind the platform</Text>
        <SimpleGrid py="2rem" columns={{ base: 1, md: 2, lg: 4 }} spacing={4}>
          {teamData.map((item) => (
            <CardTeam
              key={item.id}
              img={item.img}
              title={item.title}
              subtitle={item.subtitle}
              description={item.description}
              socials={item.socials}
            />
          ))}
        </SimpleGrid>

        <VStack mt="3rem" align="start">
          <Heading size={{ base: 'md', md: 'lg' }}>We&apos;re hiring!</Heading>
          <Text textAlign="start">Lorem ipsum dolor sit amet, consectetur adipiscing elit. </Text>

          <Button
            as="a"
            border="1px solid #46BDFF"
            color="#46BDFF"
            fontWeight="400"
            cursor="pointer"
            bg="white"
            _hover={{
              bg: 'white',
            }}
            aria-label="sign up"
            fontSize={{ base: '14px', md: '16px' }}
          >
            Open Positions{' '}
          </Button>
        </VStack>
      </VStack> */}
    </Box>
  );
};

export default Team;
