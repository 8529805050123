import { Box } from '@chakra-ui/react';
import React from 'react';
import BannerOne from './components/BannerOne';

const AboutUs = () => {
  return (
    <Box>
      <BannerOne />
    </Box>
  );
};

export default AboutUs;
