import React from 'react';
import { Box, VStack, Text, Flex, Grid } from '@chakra-ui/react';
import layout from '../../../../assets/images/layout.svg';
import { CardComponent } from '../../../../components/card';
import { careerData } from '../../../../data';
import Banner4 from '../banner4';

const Banner3 = () => {
  return (
    <>
      <Box
        id="learn-more"
        width="100%"
        background={`url(${layout})`}
        backgroundSize="cover"
        px={{ base: '1.5rem', md: '2rem', lg: '3rem' }}
        py={{ base: '2rem', md: '4rem' }}
      >
        <VStack>
          <Flex
            flexDir={{ base: 'column', md: 'row' }}
            w="100%"
            justify="space-between"
            gap={{ base: '1rem' }}
          >
            <Text
              width={{ base: '100%', md: '50%' }}
              fontWeight="700"
              fontSize={{ base: '1.5rem', md: '1.9rem' }}
            >
              Easy-to-Use Interface
            </Text>
            <Text width={{ base: '100%', md: '50%' }} fontSize={{ base: '1rem', md: '1.2rem' }}>
              Our AI-powered service provides personalized career advisory and tailored learning
              plans to help students make informed decisions about their future. With our
              easy-to-use interface, students can input their preferences and receive a personalized
              guide to help them navigate their educational and career paths.
            </Text>
          </Flex>

          <Grid
            templateColumns={{ base: '1fr', md: 'repeat(3, 1fr)' }}
            gap={{ base: '2rem', md: '4rem' }}
            mt={{ base: '2rem', md: '4rem' }}
            width="100%"
          >
            {careerData.map((item) => (
              <Box key={item.id}>
                <CardComponent
                  img={item.img}
                  title={item.title}
                  description={item.description}
                  link={item.link}
                  path={item.path}
                />
              </Box>
            ))}
          </Grid>
        </VStack>
      </Box>
      <Banner4 />
    </>
  );
};

export default Banner3;
