import { Box, Grid, Heading, Flex } from '@chakra-ui/react';
import React from 'react';
import { careerPath } from '../../../../data';
import { CardCareerPath } from '../../../../components/card';
import Banner5 from '../banner5';

const Banner4 = () => {
  return (
    <>
      <Box
        px={{ base: '1.5rem', md: '2rem', lg: '3rem' }}
        py={{ base: '2rem', md: '4rem' }}
        width="100%"
      >
        <Flex justify="center" align="center">
          <Heading
            fontSize={{ base: '1.5rem', md: '1.9rem' }}
            width={{ lg: '50%' }}
            textAlign="center"
          >
            Discover Your Perfect Career Path with Personalized Guidance
          </Heading>
        </Flex>
        <Grid
          templateColumns={{ base: '1fr', md: 'repeat(3, 1fr)' }}
          gap={{ base: '2rem', md: '4rem' }}
          mt={{ base: '2rem', md: '4rem' }}
          width="100%"
          mb={{ base: '1rem', md: '1.5rem' }}
        >
          {careerPath.map((item, index) => (
            <Box key={item.id}>
              <CardCareerPath
                index={index}
                img={item.img}
                title={item.title}
                description={item.description}
                link={item.link}
                path={item.path}
              />
            </Box>
          ))}
        </Grid>
      </Box>
          <Banner5 />
    </>
  );
};

export default Banner4;
