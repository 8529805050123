import React from 'react';
import { Box, Flex, Heading, Text, VStack, Image, Button } from '@chakra-ui/react';
import stroke from '../../../../assets/images/stroke.svg';
import { Routes } from '../../../../constant';
import { useNavigate } from 'react-router-dom';
import Banner2 from '../banner2';
import Banner3 from '../banner3';

const Banner1 = () => {
  const navigate = useNavigate();
  return (
    <Box h="100vh" py={{ base: '2rem', md: '4rem' }}>
      <Box px={{ base: '1.5rem', md: '2rem', lg: '3rem' }}>
        <Flex
          w="100%"
          justify={{ lg: 'space-between' }}
          flexDir={{ base: 'column', md: 'column', lg: 'row' }}
          mb={{ base: '0', lg: '-54.5' }}
        >
          <VStack
            width={{ base: '100%', md: '100%', lg: '40%' }}
            pos="relative"
            gap={{ base: '1rem', md: '2.5rem' }}
          >
            <Heading lineHeight="tall" fontSize={{ base: '1.8rem', md: '2.7rem' }}>
              <Box pos="relative">
                <>
                  <Text>
                    AI-Powered Career Advisory and{' '}
                    <span
                      style={{
                        fontStyle: 'italic',
                        fontWeight: '400',
                        position: 'relative',
                      }}
                    >
                      Personalized
                      <Image
                        pos="absolute"
                        transform="translate(-50%, -50%)" // Center the image relative to the text
                        width={{ base: '158px', md: '240px' }}
                        top="calc(100% - .rem)" // Adjust this value as needed
                        left={{ base: 'calc(80% - 3.2rem)', md: 'calc(80% - 4.6rem)' }} // Fixed position from the left
                        src={stroke}
                        alt="stroke"
                      />
                    </span>{' '}
                    Learning Plans for Students
                  </Text>
                </>
              </Box>
            </Heading>
            <Text width="100%" fontSize={{ base: '1rem', md: '1.2rem' }}>
              Unlock your potential with our AI-driven platform that guides your educational
              journey.{' '}
            </Text>

            <Flex w="100%" gap="1rem" justify="start">
              <Button
                as="a"
                href="#learn-more"
                width={{ base: '40%', md: '30%' }}
                height={{ base: '40px', md: '50px' }}
                bg="#46BDFF"
                fontWeight="400"
                color="white"
                _hover={{
                  bgGradient: 'linear(to-r, #46BDFF, pink.100)',
                  color: 'white',
                }}
                aria-label="get started"
                fontSize={{ base: '14px', md: '16px' }}
              >
                Learn More{' '}
              </Button>
              <Button
                width={{ base: '40%', md: '30%' }}
                height={{ base: '40px', md: '50px' }}
                color="#46BDFF"
                fontWeight="400"
                border="1px solid #46BDFF"
                bg="white"
                _hover={{
                  bg: 'white',
                  border: '1.5px solid #46BDFF',
                }}
                aria-label="sign in"
                onClick={() => navigate(Routes.ComingSoon)}
                fontSize={{ base: '14px', md: '16px' }}
              >
                Get Started
              </Button>
            </Flex>
          </VStack>

          <Box
            flexShrink={0}
            width={{ base: '100%', lg: '450px' }}
            height={{ base: '100px', md: '200px', lg: '550px' }}
            filter="blur(120px)"
            bgGradient="linear-gradient(180deg, rgba(254, 4, 89, 0.19) 0%, rgba(72, 187, 253, 0.19) 100%)"
          ></Box>
        </Flex>
        <Banner2 />
      </Box>
      <Banner3 />
    </Box>
  );
};

export default Banner1;
