import { Box, VStack, Text, Heading, Flex, Button } from '@chakra-ui/react';
import empower from '../../../../assets/images/empower.png';
import React from 'react';
import { Routes } from '../../../../constant';
import { useNavigate } from 'react-router-dom';
import BannerTwo from '../BannerTwo';
import Banner8 from '../../../landing-page/components/banner8';

const BannerOne = () => {
  const navigate = useNavigate();
  return (
    <Box w="100%">
      <VStack>
        <Flex
          background={`url(${empower})`}
          backgroundSize="cover"
          width="100%"
          height="490px"
          justify="center"
          align="center"
          px={{ base: '1.5rem', md: '2rem', lg: '3rem' }}
        >
          <VStack gap="1rem" align="center" w="100%">
            <Heading
              textAlign="center"
              w={{ base: '75%' }}
              fontSize={{ base: '1.95rem', md: '2.6rem' }}
              color="white"
            >
              Empowering Student Success
            </Heading>
            <Text textAlign="center" color="white">
              We use AI to provide personalized career advice and learning plans for students.
            </Text>
          </VStack>
        </Flex>
        <Box py={{ base: '2rem', md: '4rem' }} px={{ base: '1.5rem', md: '2rem', lg: '3rem' }}>
          <Flex
            w="100%"
            justify={{ lg: 'space-between' }}
            flexDir={{ base: 'column', md: 'column', lg: 'row' }}
          >
            <VStack align="start" width="50%">
              <Flex
                align="center"
                justify="center"
                borderRadius="7px"
                bg="#FE085B1A"
                width="118px"
                height="31px"
              >
                <Text color="#FE0459">Empowering</Text>
              </Flex>

              <Heading lineHeight="1.3" fontSize={{ base: '1.8rem', md: '2.2rem' }}>
                Transforming Education Through AI-Powered Career Guidance{' '}
              </Heading>
            </VStack>

            <VStack width={{ base: '100%', md: '45%' }} gap="1.5rem">
              <Text>
                At our core, we believe that every student deserves personalized career advice and a
                tailored learning plan. That&apos;s why we&apos;ve developed an AI-powered platform
                that leverages cutting-edge technology to provide students with the guidance they
                need to make informed decisions about their future.
              </Text>
              <Flex w="100%" gap="1rem" justify="start">
                <Button
                  as="a"
                  border="1px solid #46BDFF"
                  color="#46BDFF"
                  fontWeight="400"
                  cursor="pointer"
                  bg="white"
                  _hover={{
                    bg: 'white',
                  }}
                  aria-label="sign up"
                  onClick={() => navigate(Routes.ComingSoon)}
                  fontSize={{ base: '14px', md: '16px' }}
                >
                  Sign Up
                </Button>
                <Button
                  color="#46BDFF"
                  fontWeight="400"
                  bg="white"
                  _hover={{
                    bg: 'white',
                  }}
                  aria-label="learn more"
                  fontSize={{ base: '14px', md: '16px' }}
                >
                  Learn More &rarr;
                </Button>
              </Flex>
            </VStack>
          </Flex>
        </Box>
        <BannerTwo />
      </VStack>
      <Banner8 />
    </Box>
  );
};

export default BannerOne;
