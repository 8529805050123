import {
  Box,
  Flex,
  Heading,
  VStack,
  Text,
  Button,
  Accordion,
 
} from '@chakra-ui/react';
import React from 'react';
import Accordions from '../../../components/accordion';
import { faqs } from '../../../data';
import GetInTouch from './GetInTouch';

const BannerTwo = () => {
  return (
    <Box py={{ base: '2rem', md: '4rem' }} px={{ base: '1.5rem', md: '2rem', lg: '3rem' }}>
      <Flex
        flexDir={{ base: 'column', md: 'column', lg: 'row' }}
        gap={{ base: '1rem', md: '1rem' }}
      >
        <VStack align="start">
          <Heading>Common</Heading>
          <Text>
            Find answers to frequently asked questions about our services, pricing, and how our
            platform works.
          </Text>
          <Button
            border="1px solid #46BDFF"
            fontWeight="400"
            color="#46BDFF"
            bg="white"
            _hover={{
              bg: 'white',
            }}
            aria-label="sign up"
          >
            Contact Us
          </Button>
        </VStack>
        <VStack w="100%" gap="5rem">
          <Accordion
            w={{ base: '100%', md: '70%' }}
            borderRadius="1rem"
            bg="#FE04590A"
            defaultIndex={[]}
            allowToggle
          >
            {faqs.map((faq, index) => (
              <Accordions key={index} title={faq.title} content={faq.content} />
            ))}
          </Accordion>
        </VStack>{' '}
      </Flex>
     <GetInTouch/>
    </Box>
  );
};

export default BannerTwo;
