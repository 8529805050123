import { Box, Flex, Text, Input, Textarea, Button } from '@chakra-ui/react';
import React from 'react';

const Feedback = () => {
  const feedbackfields = [
    {
      placeholder: 'First Name',
    },
    {
      placeholder: 'Last Name',
    },
    {
      placeholder: 'Email Address',
    },
    {
      placeholder: 'Phone Number',
    },
  ];
  return (
    <Box
      pr={['1.5rem', '1rem', '2.8rem']}
      pl={'1.5rem'}
      py={'30px'}
      h={['100vh', '100vh', '90vh']}
      overflowY={'auto'}
    >
      <Flex flexDir={'column'} gap={'6px'}>
        <Text
          fontSize={['16px', '16px', '20px']}
          fontWeight={'600'}
          lineHeight={['24px', '24px', '32px']}
          letterSpacing={['-0.32', '-0.32', '-0.4px']}
        >
          we’ve been waiting to hear from you!
        </Text>
        <Text fontSize={'14px'} fontWeight={'400'} lineHeight={'20px'}>
          Drop us a message. We would love to make your experience better
        </Text>
      </Flex>

      <Box as="form">
        <Flex flexDir={'column'} gap={'1.5rem'} pt={'2rem'}>
          {feedbackfields.map((item) => (
            <Input
              borderRadius={'5px'}
              key={item.placeholder}
              p={'12px'}
              w={['100%', '100%', '480px']}
              h={'50px'}
              bg={'#F8F8F8'}
              placeholder={item.placeholder}
              _placeholder={{
                fontSize: '14px',
                fontWeight: '400',
                lineHeight: '21px',
                color: 'rgba(80, 80, 80, 0.29)',
              }}
            />
          ))}
          <Textarea
            p={'12px'}
            borderRadius={'5px'}
            w={['100%', '100%', '480px']}
            h={'176px'}
            resize={'none'}
            bg={'#F8F8F8'}
            placeholder="Prompt"
            _placeholder={{
              fontSize: '14px',
              fontWeight: '400',
              lineHeight: '21px',
              color: 'rgba(80, 80, 80, 0.29)',
            }}
          />
          <Button
            borderRadius={'6px'}
            bg={'#46BDFF'}
            w={['100%', '100%', '480px']}
            py={'13px'}
            _hover={{
              bg: '#46BDFF',
            }}
            type="submit"
            mb={['44px', '44px', '0']}
          >
            <Text color={'#fff'} fontSize={'14px'} fontWeight={'600'} lineHeight={'24px'}>
              Execute
            </Text>
          </Button>
        </Flex>
      </Box>
    </Box>
  );
};

export default Feedback;
