import { Flex, VStack, Image, Text } from '@chakra-ui/react';
import React from 'react'
import people from '../../../assets/images/recommendations.png';

const Recommendation = () => {
  return (
    <Flex
      w="100%"
      align="center"
      justify={{ lg: 'space-between' }}
      flexDir={{ base: 'column-reverse', md: 'column-reverse', lg: 'row' }}
    >
      <VStack>
        <Image w={{ base: '100%' }} src={people} alt="img" />
      </VStack>
      <VStack align="start" width={{ base: '100%', md: '90%', lg: '45%' }} gap="2rem">
        <VStack align="start" borderLeft="1px solid red" borderLeftWidth="2px" pl="1rem">
          <Text color="#FE0459" fontWeight="550" fontSize={{ base: '1.2rem', md: '1.3rem' }}>
            Accurate Recommendations
          </Text>
          <Text>
            Our AI technology analyzes your interests, skills, and goals to provide accurate career
            recommendations that align with your unique profile. We take into account the latest
            industry trends and job market data to ensure the advice we offer is up-to-date and
            relevant.
          </Text>
        </VStack>
        <VStack align="start">
          <Text fontWeight="550" fontSize={{ base: '1.2rem', md: '1.3rem' }}>
            Comprehensive Assessments
          </Text>
          <Text>
            Through our comprehensive assessments, we evaluate your strengths, weaknesses, and areas
            of improvement. This holistic approach helps us understand your potential and guide you
            towards the right career path. Our assessments cover various aspects, including
            personality traits, aptitude, and interests.
          </Text>
        </VStack>
        <VStack align="start" mb="1.5rem">
          <Text fontWeight="550" fontSize={{ base: '1.2rem', md: '1.3rem' }}>
            Ongoing Support
          </Text>
          <Text>
            We believe that career guidance is an ongoing process. That&apos;s why we provide
            continuous support to help you navigate your career journey. Our team of experts is
            available to answer your questions, provide additional resources, and assist you at
            every step of the way.
          </Text>
        </VStack>
      </VStack>
    </Flex>
  );
}

export default Recommendation
