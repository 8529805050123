import React from 'react';
import { Box, Text, Center } from '@chakra-ui/react';

const UnderConstruction = () => {
    return (
        <Center height="100vh">
            <Box p="4" borderWidth="1px" borderRadius="md" shadow="lg">
                <Text fontSize="2xl" fontWeight="bold" mb="4">
                    Site Under Construction
                </Text>
                <Text fontSize="lg">
                    We&apos;re working on making our website even better for you. Please check back later.
                </Text>
            </Box>
        </Center>
    );
};

export default UnderConstruction;
