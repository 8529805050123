import { Box, Text } from '@chakra-ui/react';
import React from 'react';

const ComingSoon = () => {
  return (
    <Box
      w={'300px'}
      h={'150px'}
      bg={'#F4F9FB'}
      border={'1px solid rgba(146, 146, 146, 0.14)'}
      display={'flex'}
      alignItems={'center'}
      justifyContent={'center'}
      borderRadius={'8px'}
    >
      <Text fontSize={'20px'} fontWeight={'500'} color={'#999'}>
        Coming Soon
      </Text>
    </Box>
  );
};

export default ComingSoon;
