import { Button, Flex, Heading, Text, VStack, Image } from '@chakra-ui/react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Routes } from '../../../../constant';
import discovery from '../../../../assets/images/images.png';
import Banner2 from '../banner2';
import Banner3 from '../banner3';
import Banner4 from '../banner4';
import Banner5 from '../banner5';
import Footer from '../../../../components/footer';
import Recommendation from '../Recommendation';

const Banner1 = () => {
  const navigate = useNavigate();
  return (
    <VStack align="start" h="100vh" py={{ base: '2rem', md: '4rem' }}>
      <VStack gap={{ base: '6rem', md: '10rem' }} px={{ base: '1.5rem', md: '2rem', lg: '3rem' }}>
        <Flex
          w="100%"
          justify={{ lg: 'space-between' }}
          flexDir={{ base: 'column', md: 'column', lg: 'row' }}
        >
          <VStack align="start" width="100%">
            <Flex
              align="center"
              justify="center"
              borderRadius="7px"
              bg="#FE085B1A"
              width="118px"
              height="31px"
            >
              <Text color="#FE0459">Empowering</Text>
            </Flex>

            <Heading lineHeight="tall" fontSize={{ base: '1.8rem', md: '2.7rem' }}>
              Discover Your Path
            </Heading>
          </VStack>

          <VStack width={{ base: '100%', md: '75%' }} gap="1.5rem">
            <Text>
              Unlock your potential and find the perfect career with our AI-powered Career Advice
              service. Get personalized guidance and a tailored learning plan to achieve your goals.
            </Text>
            <Flex w="100%" gap="1rem" justify="start">
              <Button
                as="a"
                href="#learn-more"
                bg="#46BDFF"
                fontWeight="400"
                color="white"
                _hover={{
                  bgGradient: 'linear(to-r, #46BDFF, pink.100)',
                  color: 'white',
                }}
                aria-label="sign up"
                onClick={() => navigate(Routes.ComingSoon)}
                fontSize={{ base: '14px', md: '16px' }}
              >
                Sign Up
              </Button>
              <Button
                color="#46BDFF"
                fontWeight="400"
                border="1px solid #46BDFF"
                bg="white"
                _hover={{
                  bg: 'white',
                  border: '1.5px solid #46BDFF',
                }}
                aria-label="learn more"
                fontSize={{ base: '14px', md: '16px' }}
              >
                Learn More
              </Button>
            </Flex>
          </VStack>
        </Flex>
        <Flex
          w="100%"
          align="center"
          justify={{ lg: 'space-between' }}
          flexDir={{ base: 'column', md: 'column', lg: 'row' }}
        >
          <VStack align="start" width={{ base: '100%', md: '90%', lg: '45%' }} gap="2rem">
            <Flex
              align={{ md: 'start', lg: 'center' }}
              justify={{ md: 'start', lg: 'center' }}
              borderRadius="7px"
              bg="#01F0B612"
              width="118px"
              height="31px"
            >
              <Text color="#01F0B6">Empowering</Text>
            </Flex>
            <Heading lineHeight="1.2" fontSize={{ base: '1.8rem', md: '2.7rem' }}>
              Personalized Career Advice Powered by AI Technology
            </Heading>
            <Text>
              Discover your true potential and find your dream career with our AI-powered
              personalized career advice. Get tailored recommendations and a customized learning
              plan to achieve your goals.
            </Text>
            <Flex w="100%" gap="1rem" justify="start" mb="1rem">
              <Button
                as="a"
                color="#46BDFF"
                fontWeight="400"
                border="1px solid #46BDFF"
                bg="white"
                aria-label="sign up"
                onClick={() => navigate(Routes.ComingSoon)}
                fontSize={{ base: '14px', md: '16px' }}
              >
                Sign Up
              </Button>
              <Button
                color="#46BDFF"
                fontWeight="400"
                bg="white"
                _hover={{
                  bg: '#46beff19',
                }}
                aria-label="learn more"
                fontSize={{ base: '14px', md: '16px' }}
              >
                Learn More &rarr;
              </Button>
            </Flex>
          </VStack>
          <VStack>
            <Image w={{ base: '100%' }} src={discovery} alt="img" />
          </VStack>
        </Flex>
        <Recommendation />
      </VStack>
      <Banner2 />
      <Banner3 />
      <Banner4 />
      <Banner5 />
      <Footer />
    </VStack>
  );
};

export default Banner1;
