import {
  Box,
  Flex,
  Grid,
  GridItem,
  Icon,
  Image,
  Text,
  Button,
  Divider,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from '@chakra-ui/react';
import { Link, NavLink } from 'react-router-dom';
import React, { useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import logo from '../../assets/icons/logo.svg';
import { GoHome } from 'react-icons/go';
import { BsBell } from 'react-icons/bs';
import { FaUserCircle } from 'react-icons/fa';
import { PiChats, PiChatDots, PiNotebook } from 'react-icons/pi';
import notification from '../../assets/icons/notification.svg';
import menu from '../../assets/icons/menu.svg';
import close from '../../assets/icons/close.svg';
import logoutIcon from '../../assets/icons/logout.svg';
import '../../index.css';
import Banner1 from '../../pages/dashboard/sidebar';
import { Routes } from '../../constant';
import '../../App.css';
import { logout, reset } from '../../store/Auth';
import { ThunkDispatch, AnyAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { useDispatch, useSelector } from 'react-redux';

const DashboardLayout = () => {
  const navigate = useNavigate();
  const dispatch: ThunkDispatch<RootState, any, AnyAction> = useDispatch();

  const { user } = useSelector((state: any) => state.auth);

  const handleLogout = async () => {
    await dispatch(logout());
    await dispatch(reset());
    navigate(Routes.Home);
  };

  const [mobileNav, setMobileNav] = useState<boolean>(false);

  const dashboardNavs = [
    {
      icon: GoHome,
      name: 'Home',
      path: Routes.DashboardHome,
    },
    {
      icon: PiChats,
      name: 'Check-ins',
      path: Routes.DashboardCheckIn,
    },
    {
      icon: PiChatDots,
      name: 'Feedbacks',
      path: Routes.DashboardFeedbacks,
    },
    {
      icon: PiNotebook,
      name: 'Learning',
      path: Routes.DashboardLearning,
    },
  ];
  return (
    user && (
      <Box h={'100vh'} overflowY={'hidden'}>
        <Box bg={'#F4F9FB'} py={'10px'} borderBottom={'1px solid rgba(146, 146, 146, 0.14)'}>
          <Flex
            maxW={'1216px'}
            px={'1rem'}
            mx={'auto'}
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            <Image
              cursor={'pointer'}
              onClick={() => navigate(Routes.DashboardHome)}
              w={'122px'}
              src={logo}
              alt="logo"
            />

            <Flex display={['none', 'none', 'flex']} gap={'2rem'}>
              {dashboardNavs.map((dashboardNav) => (
                <NavLink to={dashboardNav.path} key={dashboardNav.name}>
                  <Flex alignItems={'center'} gap={'.4rem'}>
                    <Icon as={dashboardNav.icon} fontSize={'24px'} />
                    <Text fontSize={'14px'} fontWeight={'500'} lineHeight={'24px'}>
                      {dashboardNav.name}
                    </Text>
                  </Flex>
                </NavLink>
              ))}
            </Flex>

            <Flex display={['none', 'none', 'flex']} alignItems={'center'} gap={'.6rem'}>
              <Link to={'#'}>
                <Image src={notification} alt="image" />
              </Link>
              <Menu>
                <MenuButton>
                  <Button _hover={{ bg: 'none' }} bg={'none'}>
                    <Icon as={FaUserCircle} fontSize={'30px'} />
                  </Button>
                </MenuButton>
                <MenuList>
                  <MenuItem>
                    <Link to={'#'}>
                      <Flex alignItems={'center'} gap={'1rem'}>
                        <Icon as={FaUserCircle} fontSize={'30px'} />
                        <Text
                          color={'#475367'}
                          fontSize={'12px'}
                          fontWeight={'400'}
                          lineHeight={'24px'}
                        >
                          {user && user.firstName} {user && user.lastName}
                        </Text>
                      </Flex>
                    </Link>
                  </MenuItem>
                  <Divider mb={'1rem'} mt={'.5rem'} />
                  {user && (
                    <MenuItem p={'0'}>
                      <Button
                        _hover={{
                          bg: 'none',
                        }}
                        p={'0'}
                        bg={'none'}
                        display={'flex'}
                        justifyContent={'flex-start'}
                        onClick={() => handleLogout()}
                        w={'100%'}
                      >
                        <Flex alignItems={'center'} gap={'1rem'} pl={'.5rem'}>
                          <Image src={logoutIcon} alt="image" />
                          <Text
                            color={'#EE0000'}
                            fontSize={'12px'}
                            fontWeight={'400'}
                            lineHeight={'24px'}
                          >
                            Logout
                          </Text>
                        </Flex>
                      </Button>
                    </MenuItem>
                  )}
                </MenuList>
              </Menu>
            </Flex>

            <Button
              aria-label="Open Menu"
              display={['flex', 'flex', 'none']}
              onClick={() => setMobileNav(true)}
              bg={'none'}
              _hover={{
                bg: 'none',
              }}
            >
              <Image cursor={'pointer'} src={menu} alt="image" />
            </Button>

            <Flex
              display={['flex', 'flex', 'none']}
              pos={'fixed'}
              h={'100vh'}
              w={'100%'}
              bg={'#fff'}
              zIndex={'10'}
              left={'0'}
              top={'0'}
              bottom={'0'}
              right={'0'}
              overflowY={'auto'}
              flexDirection={'column'}
              justifyContent={'space-between'}
              py={'5rem'}
              px={'2rem'}
              className={`${mobileNav && 'open'} mobile_nav`}
            >
              <Button
                pos={'absolute'}
                top={'0'}
                right={'0'}
                pr={'2rem'}
                pt={'2rem'}
                aria-label="Open Menu"
                display={['flex', 'flex', 'none']}
                onClick={() => setMobileNav(false)}
                bg={'none'}
                _hover={{
                  bg: 'none',
                }}
              >
                <Image cursor={'pointer'} src={close} alt="image" />
              </Button>
              <Flex flexDir={'column'} gap={'3rem'}>
                <Link to={'#'} onClick={() => setMobileNav(false)}>
                  <Flex alignItems={'center'} gap={'1rem'}>
                    <Icon as={FaUserCircle} fontSize={'30px'} />
                    <Text
                      color={'#475367'}
                      fontSize={'12px'}
                      fontWeight={'400'}
                      lineHeight={'24px'}
                    >
                      {user && user.firstName} {user && user.lastName}
                    </Text>
                  </Flex>
                </Link>

                <Flex display={['flex', 'flex', 'none']} flexDir={'column'} gap={'2rem'}>
                  {dashboardNavs.map((dashboardNav) => (
                    <NavLink
                      to={dashboardNav.path}
                      key={dashboardNav.name}
                      onClick={() => setMobileNav(false)}
                    >
                      <Flex alignItems={'center'} gap={'.4rem'}>
                        <Icon as={dashboardNav.icon} fontSize={'24px'} />
                        <Text fontSize={'14px'} fontWeight={'500'} lineHeight={'24px'}>
                          {dashboardNav.name}
                        </Text>
                      </Flex>
                    </NavLink>
                  ))}
                  <Link to={'#'} onClick={() => setMobileNav(false)}>
                    <Flex gap={'.4rem'} alignItems={'center'}>
                      <Icon as={BsBell} fontSize={'24px'} />
                      <Text fontSize={'14px'} fontWeight={'500'} lineHeight={'24px'}>
                        Notifications
                      </Text>
                    </Flex>
                  </Link>
                </Flex>
              </Flex>

              {user && (
                <Button
                  _hover={{
                    bg: 'none',
                  }}
                  p={'0'}
                  bg={'none'}
                  display={'flex'}
                  justifyContent={'flex-start'}
                  onClick={() => handleLogout()}
                  w={'100%'}
                >
                  <Flex alignItems={'center'} gap={'1rem'} pl={'.5rem'}>
                    <Image src={logoutIcon} alt="image" />
                    <Text
                      color={'#EE0000'}
                      fontSize={'12px'}
                      fontWeight={'400'}
                      lineHeight={'24px'}
                    >
                      Logout
                    </Text>
                  </Flex>
                </Button>
              )}
            </Flex>
          </Flex>
        </Box>
        <Box h={'100vh'} overflowY={'hidden'}>
          <Grid templateColumns={['1fr', '1fr', '1fr 2fr']}>
            <GridItem display={['none', 'none', 'block']}>
              <Banner1 />
            </GridItem>
            <GridItem>
              <Outlet />
            </GridItem>
          </Grid>
        </Box>
      </Box>
    )
  );
};

export default DashboardLayout;
