import { Box, Image } from '@chakra-ui/react';
import React from 'react';

const ComingSoon = () => {
  return (
    <Box h={'85vh'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
      <Box
        w={'400px'}
        h={'200px'}
        bg={'#F4F9FB'}
        border={'1px solid rgba(146, 146, 146, 0.14)'}
        display={'flex'}
        alignItems={'center'}
        justifyContent={'center'}
        borderRadius={'8px'}
      >
        <Image src="e660dff7537111806cb2e748d9fb46d4.png" alt="image" />
      </Box>
    </Box>
  );
};

export default ComingSoon;
