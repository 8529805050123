import {
  Box,
  Flex,
  Heading,
  VStack,
  Text,
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Accordion,
} from '@chakra-ui/react';
import React from 'react';
import intouch from '../../../assets/images/intouch.png';
import Team from '../../services/components/Team';
import Accordions from '../../../components/accordion';
import { faqs } from '../../../data';
import Footer from '../../../components/footer';

const Contacts = () => {
  return (
    <Box w="100%">
      <Flex
        background={`url(${intouch})`}
        backgroundSize="cover"
        width="100%"
        height={{ base: '30vh', md: '360px' }}
        justify="center"
        align="center"
        px={{ base: '1.5rem', md: '2rem', lg: '3rem' }}
      >
        {' '}
        <VStack gap="1rem" align="start" w="100%">
          <Heading fontSize={{ base: '1.95rem', md: '2.7rem' }} color="white">
            Get in Touch{' '}
          </Heading>
          <Text color="white">
            If you have any questions or need assistance, feel free to reach out to us.
          </Text>
        </VStack>
      </Flex>
      <VStack
        px={{ base: '1.5rem', md: '2rem', lg: '3rem' }}
        py={{ base: '2.5rem', md: '2.5rem', lg: '3.5rem' }}
      >
        <Flex
          align="center"
          justify="center"
          borderRadius="7px"
          bg="#46BDFF2B"
          width="118px"
          height="31px"
        >
          <Text color="#46BDFF">Connect</Text>
        </Flex>
        <Heading fontSize={{ base: '1.95rem', md: '2.7rem' }}>Get in Touch </Heading>
        <Text>Have a question or need assistance? We&apos;re here to help!</Text>
        <VStack align="start" width={{ base: '100%', md: '100%', lg: '50%' }}>
          <form action="" style={{ width: '100%' }}>
            <FormControl my={6} w="100%">
              <FormLabel w="100%">Name</FormLabel>
              <Input
                h="55px"
                w="100%"
                id="name"
                name="name"
                type="name"
                bg="rgba(208, 206, 206, 0.15)"
                fontSize={{ base: '1rem', md: '0.875rem' }}
                aria-label="name"
              />
            </FormControl>
            <FormControl my={6}>
              <FormLabel>Email</FormLabel>

              <Input
                h="55px"
                id="email"
                name="email"
                type="email"
                bg="rgba(208, 206, 206, 0.15);"
                fontSize={{ base: '1rem', md: '0.875rem' }}
                aria-label="Email"
              />
            </FormControl>
            <FormControl my={6}>
              <FormLabel>Message</FormLabel>

              <Textarea
                h="105px"
                bg="rgba(208, 206, 206, 0.15);"
                placeholder="Type your message"
                fontSize={{ base: '1rem', md: '0.875rem' }}
                aria-label="message"
              />
            </FormControl>
          </form>
          <VStack align="center" w="100%">
            <Checkbox>I agree to the Terms</Checkbox>

            <Button mt="1rem" bg="#46BDFF" color="white">
              Submit
            </Button>
          </VStack>
        </VStack>
      </VStack>
      <VStack gap="1.5rem" px={{ base: '1.5rem', md: '2rem', lg: '3rem' }}>
        <Team />

        <VStack align="center" gap="1.5rem" py={{ base: '2.5rem', md: '2.5rem', lg: '3.5rem' }}>
          <Heading fontSize={{ base: '1.95rem', md: '2.7rem' }}>Get Early Access Today! </Heading>
          <Text>Be the first to try out our latest features and services.</Text>
          <VStack align="center" width="100%">
            <Flex flexDir={{ base: 'column', md: 'column', lg: 'row' }} width="100%" gap=".5rem">
              <Input
                bg="white"
                color="#000"
                width={{ base: '100%', md: '100%', lg: '80%' }}
                type="email"
                placeholder="Enter your email"
              />
              <Button
                bg="#46BDFF"
                fontWeight="400"
                color="white"
                _hover={{
                  bgGradient: 'linear(to-r, #46BDFF, pink.100)',
                  color: 'white',
                  cursor: 'pointer',
                }}
                aria-label="try"
                fontSize={{ base: '14px', md: '16px' }}
              >
                Sign Up
              </Button>
            </Flex>
            <Text fontSize="13px">Sign Up to unlock exclusive benefits</Text>
          </VStack>
        </VStack>
        <VStack>
          <Heading fontSize={{ base: '1.95rem', md: '2.7rem' }}>FAQs </Heading>
          <Text textAlign="center">
            Find answers to common questions about our platform, services, and support.
          </Text>
        </VStack>
        <Flex w="100%" gap="1rem" flexDir={{ base: 'column', md: 'row' }}>
          <VStack w="100%" gap="5rem">
            <Accordion
              w={{ base: '100%', md: '100%' }}
              borderRadius="1rem"
              bg="#FE04590A"
              defaultIndex={[]}
              allowToggle
            >
              {faqs.map((faq, index) => (
                <Accordions key={index} title={faq.title} content={faq.content} />
              ))}
            </Accordion>
          </VStack>
          <VStack w="100%" gap="5rem">
            <Accordion
              w={{ base: '100%', md: '100%' }}
              borderRadius="1rem"
              bg="#FE04590A"
              defaultIndex={[]}
              allowToggle
            >
              {faqs.map((faq, index) => (
                <Accordions key={index} title={faq.title} content={faq.content} />
              ))}
            </Accordion>
          </VStack>
        </Flex>
        <Flex
          background="#FDD13C"
          borderRadius="15px"
          backgroundSize="cover"
          width="100%"
          height={{base:"200px",md:"300px"}}
          justify="center"
          align="center"
          px={{ base: '1.5rem', md: '2rem', lg: '3rem' }}
          my={{ base: '2rem', md: '6rem' }}
        >
          <VStack gap="1rem" align="center" w="100%">
            <Heading
              textAlign="center"
              w={{ base: '75%' }}
              fontSize={{ base: '1.5rem', md: '2rem' }}
            >
              Contact Us{' '}
            </Heading>
            <Text textAlign="center">We&apos;re here to help. Contact us for any inquiries.</Text>
            <Flex w="100%" justify="center">
              <Button
                as="a"
                bg="#46BDFF"
                fontWeight="400"
                cursor="pointer"
                color="white"
                _hover={{
                  bg: '#46BDFF',
                }}
                aria-label="get started"
                fontSize={{ base: '14px', md: '16px' }}
              >
                Contact{' '}
              </Button>
            </Flex>
          </VStack>
        </Flex>
          </VStack>
          <Footer/>
    </Box>
  );
};

export default Contacts;
