import React from 'react'
import { Box, Flex, Icon, IconButton, VStack, Image, Text } from '@chakra-ui/react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { ArrowForwardIcon, ArrowBackIcon, StarIcon } from '@chakra-ui/icons';
import { review } from '../../../data/review';
import Slider from 'react-slick';


const SocialReview = () => {
    const customPaging = (i: number) => (
      <Box
        borderRadius="50%"
        width="8px"
        height="8px"
        position="absolute"
        bgColor="#46BDFF"
        display="flex"
        justifyContent="flex-start"
        opacity={i === 0 ? 1 : 0.5}
        _hover={{ opacity: 1 }}
      />
    );

    const NextArrow = (props: any) => (
      <IconButton
        icon={<ArrowForwardIcon />}
        bgColor="#46BDFF30"
        color="#46BDFF"
        borderRadius="50%"
        size="lg"
        position="absolute"
        top="100%"
        right="0"
        transform="translateY(-50%)"
        zIndex="1"
        onClick={props.onClick}
        aria-label={''}
      />
    );

    const PrevArrow = (props: any) => (
      <IconButton
        icon={<ArrowBackIcon />}
        bgColor="#46BDFF30"
        color="#46BDFF"
        borderRadius="50%"
        size="lg"
        position="absolute"
        top="100%"
        left="0"
        transform="translateY(-50%)"
        zIndex="1"
        onClick={props.onClick}
        aria-label={''}
      />
    );
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1, // Number of reviews to show on desktop
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1, // Number of reviews to show on mobile
            slidesToScroll: 1,
          },
        },
      ],
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      customPaging: customPaging, // Custom styling for dots
    };
  return (
    <Box w="100%" maxH="100vh" p={{ base: '2.5rem 1rem', lg: '0 8rem' }}>
      <Slider {...settings}>
        {review.map((item, index) => (
          <VStack w="50%" align="center" justify="center" pb="3rem" gap="2rem" key={index}>
            <Flex align="center" justify="center">
              {[...Array(5)].map((_, index) => {
                return (
                  <Icon key={index} as={StarIcon} boxSize={4} color="#FDD13C" cursor="pointer" />
                );
              })}
            </Flex>
            <VStack gap="1rem" align="center" mt="1rem" justify="center">
              <Text
                textAlign="center"
                w={{ base: '100%', md: '80%' }}
                fontSize={{ base: '1rem', md: '1.1rem' }}
              >
                This platform has transformed the way students learn.{' '}
              </Text>
              <Flex align="center" gap="1rem">
                <Image w="56px" h="56px" borderRadius="50%" src={item.img} alt="image" />
                <Box>
                  <Text fontWeight="bold">{item.title}</Text>
                  <Text>{item.subtitle}</Text>
                </Box>
              </Flex>
            </VStack>
          </VStack>
        ))}
      </Slider>
    </Box>
  );
}

export default SocialReview
