// import { Box, VStack, Flex, Text, Heading, SimpleGrid, Button } from '@chakra-ui/react';
import { Box } from '@chakra-ui/react';
import React from 'react';
// import { CardTeam } from '../../../../components/card';
// import { teamData } from '../../../../data';
// import yellow from '../../../../assets/images/yellow.svg';

const BannerTwo = () => {
  return (
    <Box>
      {/* <Box py={{ base: '2rem', md: '4rem' }} px={{ base: '1.5rem', md: '2rem', lg: '3rem' }}>
      <VStack mb="2rem">
        <Flex
          align="center"
          justify="center"
          borderRadius="7px"
          bg="#FDD13C17"
          width="216px"
          height="31px"
        >
          <Text color="#FDD13C">Revolutionizing Education</Text>
        </Flex>
        <Heading lineHeight="1.3" fontSize={{ base: '1.8rem', md: '2.2rem' }}>
          Our Team{' '}
        </Heading>
        <Text textAlign="center">Passionate experts dedicated to transforming education</Text>
      </VStack>
      <SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} spacing={4}>
        {teamData.map((item) => (
          <CardTeam
            key={item.id}
            img={item.img}
            title={item.title}
            subtitle={item.subtitle}
            description={item.description}
            socials={item.socials}
          />
        ))}
      </SimpleGrid>
      <VStack mt="3rem">
        <Heading>We&apos;re hiring!</Heading>
        <Text textAlign="center">Lorem ipsum dolor sit amet, consectetur adipiscing elit. </Text>
        <Button
          as="a"
          border="1px solid #46BDFF"
          color="#46BDFF"
          fontWeight="400"
          cursor="pointer"
          bg="white"
          _hover={{
            bg: 'white',
          }}
          aria-label="sign up"
          fontSize={{ base: '14px', md: '16px' }}
        >
          Open Positions{' '}
        </Button>
      </VStack>

      <Flex
        background={`url(${yellow})`}
        backgroundSize="cover"
        width="100%"
        height="490px"
        justify="center"
        align="center"
        px={{ base: '1.5rem', md: '2rem', lg: '3rem' }}
        my={{ base: '2rem', md: '6rem' }}
      >
        <VStack gap="1rem" align="center" w="100%">
          <Heading
            textAlign="center"
            w={{ base: '75%' }}
            fontSize={{ base: '1.95rem', md: '2.6rem' }}
          >
            Transforming Education with AI
          </Heading>
          <Text textAlign="center">
            Discover your career path and get personalized learning plans with our AI-powered
            platform.
          </Text>
          <Flex w="100%" gap="1rem" justify="center">
            <Button
              as="a"
              bg="#46BDFF"
              fontWeight="400"
              cursor="pointer"
              color="white"
              _hover={{
                bg: '#46BDFF',
              }}
              aria-label="get started"
              fontSize={{ base: '14px', md: '16px' }}
            >
              Get Started
            </Button>
            <Button
              border="1px solid #46BDFF"
              color="#46BDFF"
              fontWeight="400"
              bg="none"
              _hover={{
                bg: 'none',
              }}
              aria-label="learn more"
              fontSize={{ base: '14px', md: '16px' }}
            >
              Learn More
            </Button>
          </Flex>
        </VStack>
      </Flex>
    </Box> */}
    </Box>
  );
};
export default BannerTwo;
