import { ViewOffIcon, ViewIcon } from '@chakra-ui/icons';
import {
  Box,
  VStack,
  Heading,
  Text,
  FormControl,
  Input,
  IconButton,
  InputGroup,
  InputRightElement,
  Button,
  Divider,
  Link,
  FormErrorMessage,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { Routes } from '../../../constant';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import SocialLogin from '../social_login';
import { Formik, Form } from 'formik';
import { loginUser, reset } from '../../../store/Auth';
import { useDispatch, useSelector } from 'react-redux';

import { loginValidationSchema } from '../../../utils';
import toast from 'react-hot-toast';
import { ThunkDispatch, AnyAction } from '@reduxjs/toolkit';
import { RootState } from '../../../store';
import Loader from '../../../components/loader';

const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch: ThunkDispatch<RootState, any, AnyAction> = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const { user, isLoading, isSuccess, isError, message } = useSelector((state: any) => state.auth);
  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };
  const formData = {
    email: '',
    password: '',
  };

  useEffect(() => {
    if (isError) {
      toast.error(message);
    }

    dispatch(reset());
  }, [user, isError, message, dispatch]);
  const handleSubmit = async (values: any) => {
    values.preventDefault;
    const credentials = {
      email: values.email,
      password: values.password,
    };

    const res = await dispatch(loginUser(credentials));
    console.log(res);
    if (isSuccess || res.payload.user) {
      navigate(Routes.DashboardHome);
    } else if (
      isError ||
      res.payload === undefined ||
      res.payload === null ||
      user === null ||
      user === undefined
    ) {
      navigate(Routes.Login, { state: location.state });
    }
  };
  if (isLoading) {
    <Loader />;
  }
  return (
    <Box w="100%" h="100%">
      <VStack align="center" py="1rem" w="100%">
        <Heading fontSize={{ base: '3xl', md: '3xl' }}>Sign in</Heading>
        <Text my={{ base: 2, md: 2 }}>Enter your credentials to access your account</Text>
        <Formik
          initialValues={formData}
          validationSchema={loginValidationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, errors, touched, handleChange, handleBlur }) => (
            <Form style={{ width: '100%' }}>
              <Box w="100%" p="0rem 2rem">
                <FormControl my={2}>
                  <Input
                    h="55px"
                    id="email"
                    name="email"
                    type="email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Email"
                    autoFocus
                    fontSize={{ base: '1rem', md: '0.875rem' }}
                    aria-label="Email"
                  />
                  <FormErrorMessage>
                    {errors?.email && touched.email ? errors.email : null}
                  </FormErrorMessage>
                </FormControl>
                <FormControl my={2}>
                  <InputGroup>
                    <Input
                      h="55px"
                      id="password"
                      name="password"
                      value={values.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      fontSize={{ base: '1rem', md: '0.875rem' }}
                      type={showPassword ? 'text' : 'password'}
                      placeholder="Enter your password"
                      aria-label="Password"
                    />
                    <InputRightElement h="55px">
                      <IconButton
                        variant="ghost"
                        _hover={{ bg: 'transparent' }}
                        aria-label={showPassword ? 'Hide password' : 'Show password'}
                        icon={showPassword ? <ViewOffIcon /> : <ViewIcon />}
                        onClick={handleTogglePassword}
                      />
                    </InputRightElement>
                  </InputGroup>
                  <FormErrorMessage>
                    {errors?.password && touched.password ? errors.password : null}
                  </FormErrorMessage>
                </FormControl>
                <Button
                  cursor="pointer"
                  w="100%"
                  h="55px"
                  bg="#46BDFF"
                  fontWeight="400"
                  color="white"
                  _hover={{
                    bgGradient: 'linear(to-r, #46BDFF, pink.100)',
                    color: 'white',
                  }}
                  size="md"
                  type="submit"
                  disabled={errors.email || errors.password || isLoading}
                  aria-label="Log In"
                  isLoading={isLoading}
                >
                  Log In
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
        <Divider my="2rem" />
        <SocialLogin />

        <Text my={2} fontSize="15px" align="center">
          <Link color="#46BDFF" aria-label="forgot password">
            Forgot password?
          </Link>
        </Text>
        <Text fontSize="15px" align="center">
          Don&apos;t have an account?{' '}
          <Link as={NavLink} to={Routes.Signup} color="#46BDFF" aria-label="sign up">
            Sign Up
          </Link>
        </Text>
      </VStack>
    </Box>
  );
};

export default Login;
