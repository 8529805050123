import { Box, Button, Flex, Grid, GridItem, Icon, Image, Img, Text } from '@chakra-ui/react';
import copy from '../../../assets/icons/copy.svg';
import save from '../../../assets/icons/save.svg';
import {
  BsHandThumbsDown,
  BsHandThumbsDownFill,
  BsHandThumbsUp,
  BsHandThumbsUpFill,
} from 'react-icons/bs';
import edit from '../../../assets/icons/edit.svg';
import leftArrow from '../../../assets/icons/leftarrow.svg';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Routes } from '../../../constant';
// import { updateRating } from '../../../store/AIResponse';

const Home = () => {
  const { responseOne, responseTwo, responseThree, responseFour } = useSelector(
    (state: any) => state.AIResponse,
  );

  const [liked, setLiked] = useState<boolean>(false);
  const [disLiked, setDisLiked] = useState<boolean>(false);

  // const dispatch = useDispatch();

  const navigate = useNavigate();

  const handleClick: React.MouseEventHandler<HTMLButtonElement> | undefined = (event) => {
    event.preventDefault();
    navigate(Routes.DashboardSidebar);
  };

  // const id = localStorage.getItem('id');

  const handleLike = () => {
    // dispatch(updateRating({ messageId: id, ratingType: 'dislike' }));
    if (disLiked === true) {
      setLiked(true);
      setDisLiked(false);
    }

    setLiked(true);
  };

  const handleDislike = () => {
    // dispatch(updateRating({ messageId: id, ratingType: 'dislike' }));
    if (liked === true) {
      setDisLiked(true);
      setLiked(false);
    }

    setDisLiked(true);
  };

  const handleNull = () => {
    // dispatch(updateRating({ messageId: id, ratingType: 'null' }));
    if (liked === true) {
      setLiked(false);
    }

    if (disLiked === true) {
      setDisLiked(false);
    }
  };

  return (
    <Box
      pr={['1rem', '1rem', '2.8rem']}
      pl={'1rem'}
      py={'30px'}
      h={['100vh', '100vh', '90vh']}
      overflowY={'auto'}
    >
      <Flex justifyContent={'space-between'} alignItems={'center'} pb={'30px'}>
        <Flex gap={'1rem'}>
          <Button
            bg={'none'}
            display={['block', 'block', 'none']}
            cursor={'pointer'}
            onClick={handleClick}
          >
            <Img
              display={['block', 'block', 'none']}
              cursor={'pointer'}
              src={leftArrow}
              alt="image"
            />
          </Button>
          <Text
            fontSize={['16px', '16px', '20px']}
            fontWeight={'600'}
            lineHeight={'32px'}
            letterSpacing={['-0.32', '-0.32', '-0.4px']}
          >
            AI Recommendation
          </Text>
        </Flex>
        <Button
          bg={'none'}
          display={['block', 'block', 'none']}
          cursor={'pointer'}
          onClick={handleClick}
        >
          <Image src={edit} alt="image" />
        </Button>
      </Flex>

      <Grid
        display={responseOne && responseTwo && responseThree && responseFour ? 'grid' : 'none'}
        templateColumns={['1fr', '1fr', 'repeat(2, 1fr)']}
        gap={'1.5rem'}
      >
        <GridItem
          w={'100%'}
          bg={'#F4F9FB'}
          border={'1px solid rgba(70, 189, 255, 0.40)'}
          borderRadius={'5px'}
          p={'14px 14px 20px 13px'}
        >
          <Flex flexDir={'column'} gap={'18px'}>
            <Text fontSize={'13px'} fontWeight={'600'} letterSpacing={'-0.28px'}>
              Career Recommendation
            </Text>
            <Text
              fontSize={'13px'}
              fontWeight={'400'}
              lineHeight={'20px'}
              letterSpacing={'-0.28px'}
            >
              {responseOne}
            </Text>
          </Flex>
        </GridItem>
        <GridItem
          w={'100%'}
          bg={'#F4F9FB'}
          border={'1px solid rgba(70, 189, 255, 0.40)'}
          borderRadius={'5px'}
          p={'14px 14px 20px 13px'}
        >
          <Flex flexDir={'column'} gap={'18px'}>
            <Text fontSize={'13px'} fontWeight={'600'} letterSpacing={'-0.28px'}>
              Learning Plan
            </Text>
            <Text
              fontSize={'13px'}
              fontWeight={'400'}
              lineHeight={'20px'}
              letterSpacing={'-0.28px'}
            >
              {responseTwo}
            </Text>
          </Flex>
        </GridItem>
        <GridItem
          w={'100%'}
          bg={'#F4F9FB'}
          border={'1px solid rgba(70, 189, 255, 0.40)'}
          borderRadius={'5px'}
          p={'14px 14px 20px 13px'}
        >
          <Flex flexDir={'column'} gap={'18px'}>
            <Text fontSize={'13px'} fontWeight={'600'} letterSpacing={'-0.28px'}>
              Career Insight
            </Text>
            <Text
              fontSize={'13px'}
              fontWeight={'400'}
              lineHeight={'20px'}
              letterSpacing={'-0.28px'}
            >
              {responseThree}
            </Text>
          </Flex>
        </GridItem>
        <GridItem
          w={'100%'}
          bg={'#F4F9FB'}
          border={'1px solid rgba(70, 189, 255, 0.40)'}
          borderRadius={'5px'}
          p={'14px 14px 20px 13px'}
        >
          <Flex flexDir={'column'} gap={'18px'}>
            <Text fontSize={'13px'} fontWeight={'600'} letterSpacing={'-0.28px'}>
              Knowledge Gap
            </Text>
            <Text
              fontSize={'13px'}
              fontWeight={'400'}
              lineHeight={'20px'}
              letterSpacing={'-0.28px'}
            >
              {responseFour}
            </Text>
          </Flex>
        </GridItem>
      </Grid>

      <Flex
        display={responseOne && responseTwo && responseThree && responseFour ? 'flex' : 'none'}
        justifyContent={'space-between'}
        bg={'rgba(146, 146, 146, 0.09)'}
        borderRadius={'5px'}
        p={['20px 20px', '20px 20px', '20px 40px']}
        mt={['3rem', '3rem', '7rem']}
        mb={['44px', '44px', '0']}
      >
        <Flex gap={['1rem', '1rem', '2rem']}>
          <Flex cursor={'pointer'} gap={'.5rem'} alignItems={'center'}>
            <Image src={copy} alt="image" />
            <Text color={'#0F0D0E'} fontSize={'14px'} fontFamily={'400'} lineHeight={'24px'}>
              Copy
            </Text>
          </Flex>
          <Flex cursor={'pointer'} gap={'.5rem'} alignItems={'center'}>
            <Image src={save} alt="image" />
            <Text color={'#0F0D0E'} fontSize={'14px'} fontFamily={'400'} lineHeight={'24px'}>
              Save
            </Text>
          </Flex>
        </Flex>
        <Flex gap={'1rem'}>
          <Button
            p={'0'}
            bg={'none'}
            _hover={{ bg: 'none' }}
            onClick={liked ? handleNull : handleLike}
          >
            <Icon as={liked ? BsHandThumbsUpFill : BsHandThumbsUp} />
          </Button>
          <Button
            bg={'none'}
            p={'0'}
            _hover={{ bg: 'none' }}
            onClick={disLiked ? handleNull : handleDislike}
          >
            <Icon as={disLiked ? BsHandThumbsDownFill : BsHandThumbsDown} />
          </Button>
        </Flex>
      </Flex>
    </Box>
  );
};

export default Home;
