import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Image,
  Input,
  Text,
} from '@chakra-ui/react';
import Select from 'react-select';
import edit from '../../../assets/icons/edit.svg';
import React from 'react';
import { Form, Formik } from 'formik';
import { ThunkDispatch, AnyAction } from '@reduxjs/toolkit';
import { RootState } from '../../../store';
import { useDispatch, useSelector } from 'react-redux';
import { userMessageCompletion } from '../../../store/AIResponse';
import { gender, educationlevel } from '../../../data';
import { useNavigate } from 'react-router-dom';
import { Routes } from '../../../constant';

const Banner1 = () => {
  const dispatch: ThunkDispatch<RootState, any, AnyAction> = useDispatch();

  const { isLoading } = useSelector((state: any) => state.AIResponse);
  const { user } = useSelector((state: any) => state.auth);

  const navigate = useNavigate();

  const formData = {
    skillsets: '',
    project: '',
    previouswork: '',
    Droles: '',
    Dcompanies: '',
    university: '',
    major: '',
    course: '',
    gender: '',
    educationlevel: '',
  };

  const handleSubmit = async (values: any) => {
    values.preventDefault;

    const messageData = {
      skillsets: values.skillsets,
      project: values.project,
      previouswork: values.previouswork,
      Droles: values.Droles,
      Dcompanies: values.Dcompanies,
      university: values.university,
      major: values.major,
      course: values.course,
      gender: values.gender,
      educationlevel: values.educationlevel,
    };
    await dispatch(userMessageCompletion(messageData));

    navigate(Routes.DashboardHome);
  };

  return (
    <Box
      bg={'#F4F9FB'}
      h={['100vh', '100vh', '90vh']}
      overflowY={'auto'}
      p={['29px 1.5rem 44px 1.5rem', '29px 1.5rem 44px 1.5rem', '29px 2.8rem 44px 2.8rem']}
    >
      <Flex alignItems={'center'} justifyContent={'space-between'}>
        <Box>
          <Text
            fontSize={['16px', '16px', '20px']}
            fontWeight={'600'}
            lineHeight={'24px'}
            letterSpacing={['-0.32px', '-0.32px', '-0.4px']}
          >
            Welcome {user && user.firstName}
          </Text>

          <Text
            color={'475367'}
            fontSize={['12px', '12px', '14px']}
            fontWeight={'400'}
            lineHeight={'20px'}
          >
            How can i help you today?
          </Text>
        </Box>
        <Image cursor={'pointer'} src={edit} alt="image" />
      </Flex>
      <Formik initialValues={formData} onSubmit={handleSubmit}>
        {({ values, errors, touched, handleChange, handleBlur, setFieldValue }) => (
          <Form>
            <Flex flexDir={'column'} gap={'1.5rem'} pt={'2rem'}>
              <FormControl isInvalid={errors.skillsets && touched.skillsets ? true : false}>
                <FormLabel fontSize={'14px'} fontWeight={'400'} lineHeight={'21px'} color={'#333'}>
                  Your current skill set
                </FormLabel>
                <Input
                  name="skillsets"
                  id="skillsets"
                  value={values.skillsets}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  borderRadius={'5px'}
                  p={'12px'}
                  h={'50px'}
                  bg={'#FFF'}
                  placeholder="Your current skill set"
                  _placeholder={{
                    fontSize: '14px',
                    fontWeight: '400',
                    lineHeight: '21px',
                    color: 'rgba(80, 80, 80, 0.29)',
                  }}
                />
                <FormErrorMessage>
                  {errors?.skillsets && touched.skillsets ? errors.skillsets : null}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.project && touched.project ? true : false}>
                <FormLabel fontSize={'14px'} fontWeight={'400'} lineHeight={'21px'} color={'#333'}>
                  Project you have worked on
                </FormLabel>
                <Input
                  name="project"
                  id="project"
                  value={values.project}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  borderRadius={'5px'}
                  p={'12px'}
                  h={'50px'}
                  bg={'#FFF'}
                  placeholder="Project you have worked on"
                  _placeholder={{
                    fontSize: '14px',
                    fontWeight: '400',
                    lineHeight: '21px',
                    color: 'rgba(80, 80, 80, 0.29)',
                  }}
                />
                <FormErrorMessage>
                  {errors?.project && touched.project ? errors.project : null}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.previouswork && touched.previouswork ? true : false}>
                <FormLabel fontSize={'14px'} fontWeight={'400'} lineHeight={'21px'} color={'#333'}>
                  Previous work experience
                </FormLabel>
                <Input
                  name="previouswork"
                  id="previouswork"
                  value={values.previouswork}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  borderRadius={'5px'}
                  p={'12px'}
                  h={'50px'}
                  bg={'#FFF'}
                  placeholder="Previous work experience"
                  _placeholder={{
                    fontSize: '14px',
                    fontWeight: '400',
                    lineHeight: '21px',
                    color: 'rgba(80, 80, 80, 0.29)',
                  }}
                />
                <FormErrorMessage>
                  {errors?.previouswork && touched.previouswork ? errors.previouswork : null}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.Droles && touched.Droles ? true : false}>
                <FormLabel fontSize={'14px'} fontWeight={'400'} lineHeight={'21px'} color={'#333'}>
                  Roles you would like to fill
                </FormLabel>
                <Input
                  name="Droles"
                  id="Droles"
                  value={values.Droles}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  borderRadius={'5px'}
                  p={'12px'}
                  h={'50px'}
                  bg={'#FFF'}
                  placeholder="Roles you would like to fill"
                  _placeholder={{
                    fontSize: '14px',
                    fontWeight: '400',
                    lineHeight: '21px',
                    color: 'rgba(80, 80, 80, 0.29)',
                  }}
                />
                <FormErrorMessage>
                  {errors?.Droles && touched.Droles ? errors.Droles : null}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.Dcompanies && touched.Dcompanies ? true : false}>
                <FormLabel fontSize={'14px'} fontWeight={'400'} lineHeight={'21px'} color={'#333'}>
                  Companies you would like to work with
                </FormLabel>
                <Input
                  name="Dcompanies"
                  id="Dcompanies"
                  value={values.Dcompanies}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  borderRadius={'5px'}
                  p={'12px'}
                  h={'50px'}
                  bg={'#FFF'}
                  placeholder="Companies you would like to work with"
                  _placeholder={{
                    fontSize: '14px',
                    fontWeight: '400',
                    lineHeight: '21px',
                    color: 'rgba(80, 80, 80, 0.29)',
                  }}
                />
                <FormErrorMessage>
                  {errors?.Dcompanies && touched.Dcompanies ? errors.Dcompanies : null}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.university && touched.university ? true : false}>
                <FormLabel fontSize={'14px'} fontWeight={'400'} lineHeight={'21px'} color={'#333'}>
                  University
                </FormLabel>
                <Input
                  name="university"
                  id="university"
                  value={values.university}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  borderRadius={'5px'}
                  p={'12px'}
                  h={'50px'}
                  bg={'#FFF'}
                  placeholder="University"
                  _placeholder={{
                    fontSize: '14px',
                    fontWeight: '400',
                    lineHeight: '21px',
                    color: 'rgba(80, 80, 80, 0.29)',
                  }}
                />
                <FormErrorMessage>
                  {errors?.university && touched.university ? errors.university : null}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.major && touched.major ? true : false}>
                <FormLabel fontSize={'14px'} fontWeight={'400'} lineHeight={'21px'} color={'#333'}>
                  Major
                </FormLabel>
                <Input
                  name="major"
                  id="major"
                  value={values.major}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  borderRadius={'5px'}
                  p={'12px'}
                  h={'50px'}
                  bg={'#FFF'}
                  placeholder="Major"
                  _placeholder={{
                    fontSize: '14px',
                    fontWeight: '400',
                    lineHeight: '21px',
                    color: 'rgba(80, 80, 80, 0.29)',
                  }}
                />
                <FormErrorMessage>
                  {errors?.major && touched.major ? errors.major : null}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.course && touched.course ? true : false}>
                <FormLabel fontSize={'14px'} fontWeight={'400'} lineHeight={'21px'} color={'#333'}>
                  Course
                </FormLabel>
                <Input
                  name="course"
                  id="course"
                  value={values.course}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  borderRadius={'5px'}
                  p={'12px'}
                  h={'50px'}
                  bg={'#FFF'}
                  placeholder="Course"
                  _placeholder={{
                    fontSize: '14px',
                    fontWeight: '400',
                    lineHeight: '21px',
                    color: 'rgba(80, 80, 80, 0.29)',
                  }}
                />
                <FormErrorMessage>
                  {errors?.course && touched.course ? errors.course : null}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.gender && touched.gender ? true : false}>
                <FormLabel fontSize={'14px'} fontWeight={'400'} lineHeight={'21px'} color={'#333'}>
                  Gender
                </FormLabel>
                <Select
                  id="gender"
                  name="gender"
                  options={gender}
                  value={gender.find((option: any) => option.value === values.gender) || ''}
                  onChange={(selectedOption: any) => setFieldValue('gender', selectedOption.value)}
                  onBlur={handleBlur}
                  placeholder="Gender"
                  styles={{
                    control: (base) => ({
                      ...base,
                      height: '55px',
                      fontSize: '1rem',
                      background: '#F8F8F8',
                      border: '.5px solid rgba(13, 13, 13, 0.159)',
                      borderRadius: '8px',
                      boxShadow: 'none',
                      // '&:hover': {
                      //   border: '1px solid rgba(13, 13, 13, 0.30)',
                      // },
                    }),
                    placeholder: (base) => ({
                      ...base,
                      fontSize: '14px',
                      fontWeight: '400',
                      lineHeight: '21px',
                      color: 'rgba(80, 80, 80, 0.29)',
                    }),
                  }}
                />
                <FormErrorMessage>
                  {errors?.gender && touched.gender ? errors.gender : null}
                </FormErrorMessage>
              </FormControl>

              <FormControl
                isInvalid={errors.educationlevel && touched.educationlevel ? true : false}
              >
                <FormLabel fontSize={'14px'} fontWeight={'400'} lineHeight={'21px'} color={'#333'}>
                  Education level
                </FormLabel>
                <Select
                  id="educationlevel"
                  name="educationlevel"
                  options={educationlevel}
                  value={
                    educationlevel.find((option: any) => option.value === values.educationlevel) ||
                    ''
                  }
                  onChange={(selectedOption: any) =>
                    setFieldValue('educationlevel', selectedOption.value)
                  }
                  onBlur={handleBlur}
                  placeholder="Education Level"
                  styles={{
                    control: (base) => ({
                      ...base,
                      height: '55px',
                      fontSize: '1rem',
                      background: '#F8F8F8',
                      border: '.5px solid rgba(13, 13, 13, 0.159)',
                      borderRadius: '8px',
                      boxShadow: 'none',
                      // '&:hover': {
                      //   border: '1px solid rgba(13, 13, 13, 0.30)',
                      // },
                    }),
                    placeholder: (base) => ({
                      ...base,
                      fontSize: '14px',
                      fontWeight: '400',
                      lineHeight: '21px',
                      color: 'rgba(80, 80, 80, 0.29)',
                    }),
                  }}
                />
                <FormErrorMessage>
                  {errors?.educationlevel && touched.educationlevel ? errors.educationlevel : null}
                </FormErrorMessage>
              </FormControl>

              <Button
                borderRadius={'6px'}
                bg={'#46BDFF'}
                py={'13px'}
                _hover={{
                  bg: '#46BDFF',
                }}
                type="submit"
                mb={['44px', '44px', '0']}
                isLoading={user ? isLoading : false}
              >
                <Text color={'#fff'} fontSize={'14px'} fontWeight={'600'} lineHeight={'24px'}>
                  Execute
                </Text>
              </Button>
            </Flex>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default Banner1;
