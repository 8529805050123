import { Box} from '@chakra-ui/react';
import React from 'react';
import Career from '../Career';

const personalizedData = [
  {
    title: 'Get Personalized Career Advice Today',
    subtitle: 'Discover the Perfect Career Path for You',
    button1: 'Try',
    button2: 'Learn More',
  },
];

const Banner4 = () => {
  return (
    <Box
      py={{ base: '2rem', md: '4rem' }}
      px={{ base: '1.5rem', md: '2rem', lg: '3rem' }}
      w="100%"
      bg="#FDD13C"
    >
      {personalizedData.map((data, index) => (
        <Career data={data} key={index} />
      ))}
    </Box>
  );
};

export default Banner4;
