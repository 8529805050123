import React from 'react';
import { Box, Flex, Heading, VStack, Text, Button, HStack, Image } from '@chakra-ui/react';
import testimonial from '../../../assets/images/testimonial.png';
import Reviews from '../../../components/reviews';
import boat from '../../../assets/images/boat.png'
import Banner8 from '../../landing-page/components/banner8';

const Banner = () => {
  return (
    <Box w="100%">
      <Flex
        background={`url(${testimonial})`}
        backgroundSize="cover"
        width="100%"
        height={{ base: '30vh', md: '360px' }}
        justify="center"
        align="center"
        px={{ base: '1.5rem', md: '2rem', lg: '3rem' }}
      >
        <VStack gap="1rem" align="start" w="100%">
          <Heading fontSize={{ base: '1.95rem', md: '2.7rem' }} color="white">
            Hear from Students{' '}
          </Heading>
          <Text color="white">
            Discover how our AI-powered career advice and learning plans have changed lives.{' '}
          </Text>
        </VStack>
      </Flex>
      <VStack
        align="start"
        py={{ base: '2rem', md: '4rem' }}
        px={{ base: '1.5rem', md: '2rem', lg: '3rem' }}
        gap="1rem"
      >
        <Heading fontSize={{ base: '1.95rem', md: '2.5rem' }}>Satisfied Students</Heading>
        <Text>Read what our students have to say about us</Text>

        <Reviews />
      </VStack>
      <Flex
        w="100%"
        flexDir={{ base: 'column', md: 'row' }}
        justify="space-between"
        gap={{ base: '1rem' }}
        pb={{ base: '2rem', md: '4rem' }}
        px={{ base: '1.5rem', md: '2rem', lg: '3rem' }}
      >
        <VStack align="start">
          <Heading lineHeight="1.2" fontSize={{ base: '1.8rem', md: '2.7rem' }}>
            Unlock Your Potential Today{' '}
          </Heading>
          <Text>
            Get personalized career advice and learning plans tailored to your needs and goals.
          </Text>
          <HStack>
            <Button
              as="a"
              bg="#46BDFF"
              cursor="pointer"
              _hover={{
                bgGradient: 'linear(to-r, #46BDFF, pink.100)',
                color: 'white',
              }}
              fontWeight="400"
              border="1px solid #46BDFF"
              color="white"
              aria-label="Get started"
              fontSize={{ base: '14px', md: '16px' }}
            >
              Get Started{' '}
            </Button>
            <Button
              border="1px solid #46BDFF"
              bg="none"
              color="#46BDFF"
              fontWeight="400"
              _hover={{
                bg: '#46beff19',
              }}
              aria-label="learn more"
              fontSize={{ base: '14px', md: '16px' }}
            >
              Learn More
            </Button>
          </HStack>
        </VStack>
        <Image src={boat} alt="info" />
      </Flex>
      <Banner8 />
    </Box>
  );
};

export default Banner;
