import { Box, VStack, Flex, Image, Text, Heading, Button } from '@chakra-ui/react';
import React from 'react';
import placeholder from '../../../../assets/images/placeholder_image.png';
import banner from '../../../../assets/images/banner.png';
import { useNavigate } from 'react-router-dom';
import { Routes } from '../../../../constant';

const Banner7 = () => {
  const navigate = useNavigate();
  return (
    <Box px={{ base: '1.5rem', md: '2rem', lg: '3rem' }} py={{ base: '2rem', md: '4rem' }}>
      <Box background={`url(${banner})`} backgroundSize="cover" width="100%">
        <Flex
          flexDir={{ base: 'column', md: 'column', lg: 'row' }}
          width="100%"
          justify="space-between"
        >
          <VStack
            width="100%"
            px={{ base: '1.5rem', md: '2rem', lg: '3rem' }}
            py={{ base: '1rem', md: '3.5rem' }}
            gap="1.8rem"
          >
            <Heading textAlign="left" lineHeight="1.2" fontSize={{ base: '1.5rem', md: '2.5rem' }}>
              Get Personalized Career Advice Today
            </Heading>
            <Text fontSize={{ base: '1rem', md: '1.2rem' }}>
              Unlock your potential with our AI-powered platform. Receive personalized career advice
              and learning plans.
            </Text>
            <Flex mt="2rem" w="100%" gap="1rem" justify="start">
              <Button
                height={{ base: '40px', md: '40px' }}
                bg="#46BDFF"
                fontWeight="400"
                color="white"
                _hover={{
                  bgGradient: 'linear(to-r, #46BDFF, pink.100)',
                  color: 'white',
                  cursor: 'pointer',
                }}
                cursor={'pointer'}
                aria-label="try"
                onClick={() => navigate(Routes.Discover)}
                fontSize={{ base: '14px', md: '16px' }}
              >
                Try
              </Button>
              <Button
                height={{ base: '40px', md: '40px' }}
                color="#46BDFF"
                fontWeight="400"
                border="1px solid #46BDFF"
                bg="transparent"
                _hover={{
                  border: '1.5px solid #46BDFF',
                  cursor: 'pointer',
                }}
                cursor={'pointer'}
                onClick={() => navigate(Routes.Programs)}
                aria-label="learn more"
                fontSize={{ base: '14px', md: '16px' }}
              >
                Learn More
              </Button>
            </Flex>
          </VStack>
          <Image width="100%" objectFit="cover" src={placeholder} alt="image" />
        </Flex>
      </Box>
    </Box>
  );
};

export default Banner7;
