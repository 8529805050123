import React from 'react';
import { Box } from '@chakra-ui/react';
import Banner1 from './components/banner1';

const LandingPage = () => {
  return (
    <Box height="100vh">
      <Banner1 />
    </Box>
  );
};

export default LandingPage;
