import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { Routes } from '../../constant';
import { messageCompletion } from '../../requests/AIResponse';
import config from '../../config';
import axios from 'axios';
import toast from 'react-hot-toast';

export const userMessageCompletion = createAsyncThunk(
  Routes.DashboardHome,
  async (messageData: any, thunkAPI) => {
    try {
      const response = await messageCompletion(messageData);

      return response;
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const updateRating = createAsyncThunk(
  Routes.DashboardHome,
  async (messageId: any, ratingType: any) => {
    try {
      const response = await axios.post(
        `${config.AUTH_BASE_URL}/user/messages-feedbacks/${messageId}`,
        {
          rating: ratingType,
        },
      );
      return response.data;
    } catch (error: any) {
      return error.response.data;
    }
  },
);

const responseSlice = createSlice({
  name: 'AIResponse',
  initialState: {
    isLoading: false,
    responseOne: null,
    responseTwo: null,
    responseThree: null,
    responseFour: null,
    message: '',
    error: null,
  },
  reducers: {
    reset: (state) => {
      (state.isLoading = false), (state.message = '');
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(userMessageCompletion.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(userMessageCompletion.fulfilled, (state, action: PayloadAction<any>) => {
        state.responseOne = action.payload.answer.careerRecommendation;
        state.responseTwo = action.payload.answer.learningPlan;
        state.responseThree = action.payload.answer.careerInsight;
        state.responseFour = action.payload.answer.knowledgeGap;
        state.isLoading = false;
      })
      .addCase(userMessageCompletion.rejected, (state, action: PayloadAction<any>) => {
        state.message = toast.error(action.payload);
        state.isLoading = false;
      });
    // .addCase(updateRating.pending, (state) => {
    //   state.isLoading = true;
    // })
    // .addCase(updateRating.fulfilled, (state, action: PayloadAction<any>) => {
    //   state.isLoading = false;
    //   state.message = action.payload;
    // })
    // .addCase(updateRating.rejected, (state, action: PayloadAction<any>) => {
    //   state.isLoading = false;
    //   state.error = action.payload;
    // });
  },
});

export const { reset } = responseSlice.actions;
export default responseSlice.reducer;
