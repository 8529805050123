import React from 'react';
import { Box, Flex, keyframes } from '@chakra-ui/react';

const Loader = () => {
  const spin = keyframes`
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    `;

  return (
    <Flex justify="center" pos="relative" align="center" w="100%" h="100%">
      <Box
        w="50px"
        h="50px"
        pos="absolute"
        top="100"
        borderRadius="50%"
        border="4px solid transparent"
        borderTopColor="#46BDFF"
        animation={`${spin} 1s linear infinite`}
      />
    </Flex>
  );
};

export default Loader;
