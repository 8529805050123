import React from 'react';
import { MinusIcon, AddIcon } from '@chakra-ui/icons';
import {
  VStack,
  Box,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Text,
} from '@chakra-ui/react';

const Accordions = ({ title, content }: { title: string; content: string }) => {
  return (
    <VStack align="start">
      <AccordionItem borderRadius="1rem" w="100%">
        {({ isExpanded }) => (
          <Box w="100%">
            <Text w="100%">
              <AccordionButton minH="80px" w="100%">
                <Box as="span" flex="1" textAlign="left">
                  {title}
                </Box>
                {isExpanded ? <MinusIcon fontSize="12px" /> : <AddIcon fontSize="12px" />}
              </AccordionButton>
            </Text>
            <AccordionPanel bg="#e6e8ea82" pb={4}>
              {content}
            </AccordionPanel>
          </Box>
        )}
      </AccordionItem>
    </VStack>
  );
};

export default Accordions;
