import { Box, VStack, Heading, Text, Button, Flex, Input } from '@chakra-ui/react';
import React from 'react';

const Banner2 = () => {
  return (
    <Box
      my={{ base: '2rem', md: '4rem' }}
      py={{ base: '2rem', md: '4rem' }}
      px={{ base: '1.5rem', md: '2rem', lg: '3rem' }}
      w="100%"
      h="100vh"
      bg="linear-gradient(89deg, rgba(70, 189, 255, 0.35) 1.88%, rgba(70, 189, 255, 0.00) 99.31%)"
    >
      <VStack gap="1.5rem" align="start" w={{base:"100%",md:"50%"}}>
        <Heading lineHeight="1.2" fontSize={{ base: '1.8rem', md: '2.7rem' }}>
          Get Personalized Career Advice Today{' '}
        </Heading>
        <Text>
          Fill in the form below to receive AI-generated career advice tailored to your goals.
        </Text>
        <VStack align="start" width="100%" justify="end">
          <Flex
            flexDir={{ base: 'column', md: 'column', lg: 'row' }}
            width="100%"
            gap=".5rem"
          >
            <Input
              bg="white"
              color="#000"
              width={{ base: '100%', md: '100%', lg: '60%' }}
              type="email"
              placeholder="Enter your email"
            />
            <Button
              bg="#46BDFF"
              fontWeight="400"
              color="white"
              _hover={{
                bgGradient: 'linear(to-r, #46BDFF, pink.100)',
                color: 'white',
                cursor: 'pointer',
              }}
              aria-label="try"
              fontSize={{ base: '14px', md: '16px' }}
            >
              Sign Up
            </Button>
          </Flex>
          <Text fontSize="12px">By clicking Sign Up, you agree to our Terms and Conditions.</Text>
        </VStack>
      </VStack>
    </Box>
  );
};

export default Banner2;
