import { Box, Grid } from '@chakra-ui/react';
import React from 'react';
import { CardLearning } from '../../../../components/card';
import { learningPath } from '../../../../data';
import Banner6 from '../banner6';
import Banner7 from '../banner7';
import Banner8 from '../banner8';

const Banner5 = () => {
  return (
    <>
      <Box
        width="100%"
        bgGradient="linear-gradient(180deg, rgba(1, 240, 182, 0.50) 0%, rgba(1, 240, 182, 0.00) 100%)"
        px={{ base: '1.5rem', md: '2rem', lg: '3rem' }}
        py={{ base: '2rem', md: '4rem' }}
      >
        <Grid
          templateColumns={{ base: '1fr', md: 'repeat(3, 1fr)' }}
          gap={{ base: '2rem', md: '4rem' }}
          mt={{ base: '2rem', md: '4rem' }}
          width="100%"
          mb={{ base: '1rem', md: '1.5rem' }}
        >
          {learningPath.map((item) => (
            <Box key={item.id}>
              <CardLearning
                img={item.img}
                title={item.title}
                description={item.description}
                link={item.link}
                path={item.path}
              />
            </Box>
          ))}
        </Grid>
      </Box>
          <Banner6 />
      <Banner7 />
      <Banner8 /> 
    </>
  );
};

export default Banner5;
