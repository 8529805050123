import config from '../../config';
import axios from 'axios';

export const messageCompletion = async (payload: any) => {
  try {
    const token = localStorage.getItem('token');

    const header = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${config.AUTH_BASE_URL}/user/completion-messages`,
      payload,
      header,
    );

    const id = response.data.id;
    localStorage.setItem('id', id); // Store only the id

    return response.data;
  } catch (err: any) {
    console.log(err.message);
    throw err;
  }
};

// export const rating = async () => {
//   try {
//     const id = localStorage.getItem('id');

//     const header = {
//       headers: {
//         'Content-Type': 'application/json',
//       },
//     };

//     const body = {
//       body: JSON.stringify({
//         messageId: id,
//         rating: 'null',
//       }),
//     };

//     const response = await axios.post(
//       `${config.AUTH_BASE_URL}/user/messages-feedbacks`,
//       body,
//       header,
//     );

//     console.log(response);
//   } catch (err: any) {
//     console.log(err.message);
//     throw err;
//   }
// };
