import {
  Flex,
  VStack,
  Heading,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Checkbox,
  Button,
  Text,
  Image,
} from '@chakra-ui/react';
import React from 'react';
import { socials } from '../../../data';

const GetInTouch = () => {
  return (
    <Flex
      py={{ base: '2rem', md: '4rem', lg: '6rem' }}
      justify="space-between"
      w="100%"
      flexDir={{ base: 'column', md: 'column', lg: 'row' }}
      gap={{ base: '1rem', md: '1rem' }}
    >
      <VStack align="start" width={{ base: '100%', md: '100%', lg: '50%' }}>
        <Flex
          align="center"
          justify="center"
          borderRadius="7px"
          bg="#FDD13C1C"
          width="118px"
          height="31px"
        >
          <Text color="#FDD13C">Get in Touch</Text>
        </Flex>
        <Heading lineHeight="1.3" fontSize={{ base: '1.8rem', md: '2.2rem' }}>
          Contact us{' '}
        </Heading>
        <Text>Have a question? Need more information? Reach out to us!</Text>
        <VStack py="1rem" align="start">
          {socials.map((social, index: number) => (
            <Flex gap="1rem" key={index}>
              <Image src={social.icon} alt="icon" />
              <Text maxW={'400px'}>{social.text}</Text>
            </Flex>
          ))}
        </VStack>
      </VStack>
      <VStack align="start" width={{ base: '100%', md: '100%', lg: '50%' }}>
        <form action="" style={{ width: '100%' }}>
          <FormControl my={6} w="100%">
            <FormLabel w="100%">Name</FormLabel>
            <Input
              h="55px"
              w="100%"
              id="name"
              name="name"
              type="name"
              bg="rgba(208, 206, 206, 0.15)"
              fontSize={{ base: '1rem', md: '0.875rem' }}
              aria-label="name"
            />
          </FormControl>
          <FormControl my={6}>
            <FormLabel>Email</FormLabel>

            <Input
              h="55px"
              id="email"
              name="email"
              type="email"
              bg="rgba(208, 206, 206, 0.15);"
              fontSize={{ base: '1rem', md: '0.875rem' }}
              aria-label="Email"
            />
          </FormControl>
          <FormControl my={6}>
            <FormLabel>Message</FormLabel>

            <Textarea
              h="105px"
              bg="rgba(208, 206, 206, 0.15);"
              placeholder="Type your message"
              fontSize={{ base: '1rem', md: '0.875rem' }}
              aria-label="message"
            />
          </FormControl>
        </form>
        <Checkbox defaultChecked color="#46BDFF">
          I agree to the Terms
        </Checkbox>

        <Button mt="3rem" bg="#46BDFF" color="white">
          Submit
        </Button>
      </VStack>
    </Flex>
  );
};

export default GetInTouch;
