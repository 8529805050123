import React from 'react';
import { Box, Image, Flex, keyframes } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import placeholderImage from '../../../../assets/images/placeholder.svg';
const Banner2 = () => {
  const animationKeyframes = keyframes`
  25% { transform: scale(1.3) rotate(0); border-radius: 20%; }
 
`;

  const animation = `${animationKeyframes} 3s ease-in-out infinite`;
  return (
    <Box
      width="100%"
      height={{ base: '250px', md: '420px', lg: '850px' }}
      background={`url(${placeholderImage})`}
      backgroundSize="cover"
    >
      <Flex
        as={motion.div}
        animation={animation}
        justify="center"
        align="center"
        pt={{ base: '3rem', md: '6.5rem', lg: '9rem' }}
      >
        <Image
          width={{ base: '300px', md: '550px', lg: '880px' }}
          height={{ lg: '550px' }}
          src="hero.svg"
          alt="dashboard"
        />
      </Flex>
    </Box>
  );
};

export default Banner2;
