import { extendTheme} from "@chakra-ui/react";
import color from "./color";
import { customComponents as components } from './components';


export const PrChakraTheme = extendTheme({
     color,
    fonts: {
        heading: "Univia Pro, sans-serif",
        body: "Univia Pro, sans-serif",
    },
    config: {
        initialColorMode: "light",
        useSystemColorMode: false,
    },
    components: {
        ...components
    },
});

