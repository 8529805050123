import { Box } from '@chakra-ui/react'
import React from 'react'
import Banners from './components/Banners'

const Services = () => {
  return (
    <Box>
      <Banners/>
    </Box>
  )
}

export default Services
