import React from 'react';
import { Box, Flex, Image, Text, VStack } from '@chakra-ui/react';
import { Outlet, useNavigate } from 'react-router-dom';
import logo from '../../assets/icons/logo.svg';
import { Routes } from '../../constant';

import SocialReview from '../../pages/auth-page/social_review';

const AuthLayout = () => {
  const navigate = useNavigate();

  return (
    <Flex width="100%" h="100vh" flexDir={{ base: 'column', md: 'row' }}>
      <VStack
        align={{ base: 'center', md: 'start' }}
        width={{ base: '100%', md: '50%' }}
        h="100vh"
        p={{ base: '1rem', md: '1.5rem 2.5rem', lg: '1.5rem 3rem' }}
      >
        <Image
          onClick={() => navigate(Routes.Home)}
          width={{ base: '120px', md: '150px' }}
          height="55px"
          cursor="pointer"
          src={logo}
          alt="Logo"
        />
        <Box height="100vh" w="100%" px={{ lg: '5rem', xl: '5rem' }}>
          <Outlet />
        </Box>
        <Text pb="1rem" pt={{ base: '3rem', md: '1rem' }} as="footer" fontSize="sm">
          &copy; {new Date().getFullYear()} Skilltrak.
        </Text>
      </VStack>
      <VStack
        align="center"
        justify="center"
        width={{ base: '100%', md: '50%' }}
        maxH="100vh"
        bg="#F4F4F4"
      >
        <SocialReview />
      </VStack>
    </Flex>
  );
};

export default AuthLayout;
