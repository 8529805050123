// import { Box, Image, VStack, Text, Flex } from '@chakra-ui/react';
import React from 'react';
// import stars from '../../../../assets/icons/star.svg';
// import avatar from '../../../../assets/images/avatar.png';
const Banner6 = () => {
  return (
    <div></div>
    // <Box width="100%" py={{ base: '1rem', md: '4rem' }} px={{ base: '1.5rem' }}>
    //   <VStack justify={{ base: 'center' }}>
    //     <Image src={stars} alt="rating" />
    //     <Text
    //       textAlign="center"
    //       fontSize={{ base: '1rem', md: '1.2rem' }}
    //       width={{ base: '100%', lg: '50%' }}
    //     >
    //       {' '}
    //       The platform&apos;s career advice and learning plans have been invaluable. It helped me
    //       discover my passion and chart a clear path towards my dream career.{' '}
    //     </Text>
    //     <Flex
    //       justify={{ base: 'center' }}
    //       gap="1rem"
    //       w="100%"
    //     >
    //       <Image src={avatar} alt="rating" />

    //       <VStack align="start" lineHeight="1" justify="center">
    //         <Text fontSize={{ base: '.7rem', md: '.8rem' }}>John Doe</Text>
    //         <Text fontSize={{ base: '.7rem', md: '.8rem' }}>Marketing Manager, ABC Inc.</Text>
    //       </VStack>
    //     </Flex>
    //   </VStack>
    // </Box>
  );
};

export default Banner6;
