import { Box } from '@chakra-ui/react';
import React from 'react';
import Contacts from './components/Contacts';

const ContactUs = () => {
  return (
    <Box>
      <Contacts />
    </Box>
  );
};

export default ContactUs;
