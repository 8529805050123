import { Box, Heading, VStack, Text, Accordion, Button } from '@chakra-ui/react';
import React from 'react';
import Accordions from '../../../../components/accordion';
import { faqs } from '../../../../data';

const Banner3 = () => {
  return (
    <Box px={{ base: '1.5rem', md: '', lg: '' }} w="100%" pb={{ base: '2rem', md: '4rem' }}>
      <VStack align="center">
        <Heading lineHeight="1.2" fontSize={{ base: '1.8rem', md: '2.7rem' }}>
          FAQs{' '}
        </Heading>
        <Text textAlign={{ base: 'center' }}>
          Find answers to frequently asked questions about our Career Advice service, its process,
          and benefits.
        </Text>

        <VStack w="100%" gap="5rem">
          <Accordion
            w={{ base: '100%', md: '50%' }}
            borderRadius="1rem"
            bg="#FE04590A"
            defaultIndex={[]}
            allowToggle
          >
            {faqs.map((faq, index) => (
              <Accordions key={index} title={faq.title} content={faq.content} />
            ))}
          </Accordion>
        </VStack>

        <VStack my="2rem">
          <Heading lineHeight="1.2" fontSize={{ base: '1.3rem', md: '1.8rem' }}>
            Still have questions?{' '}
          </Heading>
          <Text>Contact our support team for further assistance.</Text>
          <Button
            mt="1rem"
            color="#46BDFF"
            fontWeight="400"
            border="1px solid #46BDFF"
            bg="white"
            _hover={{
              bg: 'white',
              border: '1.5px solid #46BDFF',
            }}
            aria-label="learn more"
            fontSize={{ base: '14px', md: '16px' }}
          >
            Contact Us{' '}
          </Button>
        </VStack>
      </VStack>
    </Box>
  );
};

export default Banner3;
