import { Box, Link, Flex, Text, Divider, VStack, Image } from '@chakra-ui/react';
import React from 'react';
import logo from '../../assets/icons/logo-white.svg';
import { useNavigate } from 'react-router-dom';
import { Routes } from '../../constant';

const Footer = () => {
  const menu = [
    {
      name: 'About Us',
      path: Routes.AboutUs,
    },
    {
      name: 'Services',
      path: Routes.Services,
    },
    {
      name: 'Contact',
      path: Routes.ContactUs,
    },
    {
      name: 'FAQs',
    },
    {
      name: 'Blog',
    },
  ];

  const navigate = useNavigate();
  return (
    <Box
      as="footer"
      backgroundColor=" #1E272D"
      color="white"
      px={{ base: '1.5rem', md: '2rem', lg: '3rem' }}
      py={{ base: '2rem', md: '4rem' }}
      textAlign="center"
      w="100%"
    >
      <VStack align="center">
        <Image
          onClick={() => navigate(Routes.Home)}
          w="100px"
          h="50px"
          src={logo}
          alt="logo"
          cursor="pointer"
        />

        <Flex py="2.5rem" justify="center" align="center" gap="1.5rem">
          {menu.map((item, index) => (
            <Link cursor={'pointer'} href={item.path} key={index} fontSize="sm" aria-label="links">
              {item.name}
            </Link>
          ))}
        </Flex>
      </VStack>
      <Divider my="1rem" />
      <Flex
        flexDir={{ base: 'column-reverse', lg: 'row' }}
        justify="space-between"
        w="100%"
        align="center"
      >
        <Box>
          <Text fontSize="sm">
            &copy; {new Date().getFullYear()} Skilltrak. All rights reserved.
          </Text>
        </Box>
        <Flex
          py={{ base: '1rem', lg: '0' }}
          gap="1rem"
          flexDir={{ base: 'column-reverse', lg: 'row' }}
        >
          <Link fontSize="sm" aria-label="privacy policy">
            Privacy Policy
          </Link>
          <Link fontSize="sm" aria-label="terms and conditions">
            Terms and Conditions
          </Link>
          <Link fontSize="sm" aria-label="cookie policy">
            Cookie Policy
          </Link>
        </Flex>
      </Flex>
    </Box>
  );
};

export default Footer;
