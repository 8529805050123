import React, { lazy, Suspense } from 'react';
import './App.css';
import Loader from './components/loader';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import LandingPage from './pages/landing-page';
import UnderConstruction from './components/underconstruction';
import AuthLayout from './layouts/authLayout';
import Login from './pages/auth-page/login';
import SignUp from './pages/auth-page/signup';
import Discovery from './pages/discovery';
import AboutUs from './pages/about-us';
import Services from './pages/services';
import Testimonials from './pages/testimonials';
import Programs from './pages/programs';
import ContactUs from './pages/contactus';
import Verify from './pages/auth-page/verify';
import DashBoardHome from './pages/dashboard/dashboard-home';
import CheckIns from './pages/dashboard/check-ins';
import DashboardLayout from './layouts/dashboardLayout';
import Feedback from './pages/dashboard/feedbacks';
import Learning from './pages/dashboard/Learning';
import Banner1 from './pages/dashboard/sidebar';
import ComingSoon from './pages/coming-soon';
// import ComingSoon from './pages/coming-soon';
const ClientLayout = lazy(() => import('./layouts/clientLayout'));

function App() {
  return (
    <Suspense fallback={<Loader />}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<ClientLayout />}>
            <Route path="/" element={<LandingPage />} />
            <Route path="/discover" element={<Discovery />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/services" element={<Services />} />
            <Route path="/testimonials" element={<Testimonials />} />
            <Route path="/programs" element={<Programs />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/resources" element={<UnderConstruction />} />
            <Route path="/coming-soon" element={<ComingSoon />} />
          </Route>
          <Route path="/dashboard" element={<DashboardLayout />}>
            <Route path="sidebar" element={<Banner1 />} />
            <Route path="home" element={<DashBoardHome />} />
            <Route path="checkin" element={<CheckIns />} />
            <Route path="feedbacks" element={<Feedback />} />
            <Route path="learning" element={<Learning />} />
          </Route>
          <Route path="/auth" element={<AuthLayout />}>
            <Route path="login" element={<Login />} caseSensitive></Route>
            <Route path="signup" element={<SignUp />} caseSensitive></Route>
            <Route path="verify" element={<Verify />} caseSensitive></Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
}

export default App;
