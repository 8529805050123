import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { Routes } from '../../constant';
import { register, verify, resendOtp, login, logOut } from '../../requests/auth';

export const registerUser = createAsyncThunk(Routes.Signup, async (userData: any, thunkAPI) => {
  try {
    // Make an API request to register the user
    const response = await register(userData);

    // Extract only the necessary information from headers for serialization
    const serializableHeaders = {
      'content-type': response.headers['content-type'],
      // Include other necessary headers if needed
    };

    // Modify the action payload to include serializable headers
    const modifiedPayload = {
      ...response.data, // Include other data from the response if needed
      headers: serializableHeaders,
    };

    return modifiedPayload;
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();

    return thunkAPI.rejectWithValue(message);
  }
});
export const verifyUser = createAsyncThunk(Routes.Verify, async (otp: any, thunkAPI) => {
  try {
    const response = await verify(otp);

    // Extract only the necessary information from headers
    const serializableHeaders = {
      'content-type': response.headers['content-type'],
      // Include other necessary headers if needed
    };

    // Modify the action payload to include serializable headers
    const modifiedPayload = {
      ...response.data, // Include other data from the response if needed
      headers: serializableHeaders,
    };

    return modifiedPayload;
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();

    return thunkAPI.rejectWithValue(message);
  }
});

export const resendOTP = createAsyncThunk(Routes.Verify, async (email: any, thunkAPI) => {
  try {
    const response = await resendOtp(email);

    // Extract only the necessary information from headers
    const serializableHeaders = {
      'content-type': response.headers['content-type'],
      // Include other necessary headers if needed
    };

    // Modify the action payload to include serializable headers
    const modifiedPayload = {
      ...response.data, // Include other data from the response if needed
      headers: serializableHeaders,
    };

    return modifiedPayload;
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();

    return thunkAPI.rejectWithValue(message);
  }
});
export const loginUser = createAsyncThunk(Routes.Login, async (credentials: any, thunkAPI) => {
  try {
    return await login(credentials);
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();

    return thunkAPI.rejectWithValue(message);
  }
});
export const logout = createAsyncThunk(Routes.Logout, async () => {
  return await logOut;
});

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    user: JSON.parse(localStorage.getItem('user') as any) || null,
    message: '',
  },
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(registerUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(registerUser.fulfilled, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.user = action.payload;
      })
      .addCase(registerUser.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.user = null;
      })
      .addCase(verifyUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(verifyUser.fulfilled, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.user = action.payload;
      })
      .addCase(verifyUser.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.user = null;
      })
      .addCase(loginUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(loginUser.fulfilled, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.user = action.payload.user;
      })
      .addCase(loginUser.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.user = null;
      })
      .addCase(logout.fulfilled, (state) => {
        state.user = null;
        localStorage.removeItem('user');
        localStorage.removeItem('token');
      });
  },
});

export const { reset } = authSlice.actions;
export default authSlice.reducer;
