import config from '../../config';
import toast from 'react-hot-toast';
import axios from 'axios';
import { Routes } from '../../constant';

export const register = async (payload: any) => {
  try {
    const response = await axios.post(`${config.AUTH_BASE_URL}/user/signup`, payload);
    if (response) {
      localStorage.setItem('user', JSON.stringify(response));

      const token = response.data.token;
      localStorage.setItem('token', token); // Store only the token
    }
    toast.success(response.data.message || response.data.mesage);

    return response;
  } catch (error: any) {
    toast.error('Error during signup:', error.message);
    throw error;
  }
};

export const verify = async (otp: any) => {
  try {
    const response = await axios.post(`${config.AUTH_BASE_URL}/user/verify-otp`, otp);
    toast.success(response.data.message || response.data.mesage);

    return response;
  } catch (error: any) {
    toast.error('Error during verification:', error.message);
    throw error;
  }
};

export const resendOtp = async (email: string) => {
  try {
    const response = await axios.post(`${config.AUTH_BASE_URL}/user/resend-otp`, email);
    toast.success(response.data.message || response.data.mesage);
    return response;
  } catch (error: any) {
    toast.error('Error during OTP resend:', error.message);
    throw error;
  }
};

export const login = async (credentials: any) => {
  try {
    const response = await axios.post(`${config.AUTH_BASE_URL}/user/signin`, credentials);
    if (response) {
      const token = response.data.token;
      localStorage.setItem('token', token);
      localStorage.setItem('user', JSON.stringify(response.data.user));
    }
    if (response.status === 401) {
      window.location.href = Routes.Login;
    }
    toast.success(response.data.message || response.data.mesage || 'Login successful');
    return response.data;
  } catch (error: any) {
    toast.error('Error during login:', error.message);
    throw error;
  }
};

export const logOut = () => {
  localStorage.removeItem('user');
  localStorage.removeItem('token');
};
