import { Box, Flex, Grid, VStack, Text, Heading, Button, Image } from '@chakra-ui/react';
import React from 'react';
import { CardCareerPath } from '../../../components/card';
import { careerPath3 } from '../../../data';
import { useNavigate } from 'react-router-dom';
import { Routes } from '../../../constant';
import laptop from '../../../assets/images/laptop.png';
import Banner4 from '../../discovery/components/banner4';
import BannerTwo from './BannerTwo';
import Footer from '../../../components/footer';
import Team from './Team';

const BannerOne = () => {
  const navigate = useNavigate();
  return (
    <>
      <Box
        w="100%"
        py={{ base: '2rem', md: '4rem' }}
        px={{ base: '1.5rem', md: '2rem', lg: '3rem' }}
      >
        <Grid
          templateColumns={{ base: '1fr', md: 'repeat(3, 1fr)' }}
          gap={{ base: '2rem', md: '4rem' }}
          mt={{ base: '2rem', md: '4rem' }}
          width="100%"
          mb={{ base: '1rem', md: '1.5rem' }}
        >
          {careerPath3.map((item, index) => (
            <Box key={item.id}>
              <CardCareerPath
                index={index}
                img={item.img}
                title={item.title}
                description={item.description}
                button={item.button}
              />
            </Box>
          ))}
        </Grid>
        <Flex
          w="100%"
          align="center"
          justify={{ lg: 'space-between' }}
          flexDir={{ base: 'column', md: 'column', lg: 'row' }}
          py={{ base: '2rem', md: '4rem', lg: '6rem' }}
        >
          <VStack align="start" gap="2.5rem" width={{ base: '100%', md: '100%', lg: '50%' }}>
            <Flex
              align="center"
              justify="center"
              borderRadius="7px"
              bg="#FE085B1A"
              width="118px"
              height="31px"
            >
              <Text color="#FE0459">Empower</Text>
            </Flex>
            <Heading lineHeight="1.3" fontSize={{ base: '1.8rem', md: '2.2rem' }}>
              Unlock Your Potential with Personalized Learning{' '}
            </Heading>
            <Text>
              Experience improved career prospects, tailored learning experiences, and ongoing
              support with our AI-powered platform.
            </Text>
            <Flex
              flexDir={{ base: 'column', md: 'column', lg: 'row' }}
              gap={{ base: '1rem', md: '1rem' }}
            >
              <VStack align="start">
                <Text fontWeight="bold" fontSize={{ base: '1rem', md: '1.1rem' }}>
                  Career Boost
                </Text>
                <Text>
                  Get personalized career advice and a tailored learning plan to achieve your goals.
                </Text>
              </VStack>
              <VStack align="start">
                <Text fontWeight="bold" fontSize={{ base: '1rem', md: '1.1rem' }}>
                  Supportive Community
                </Text>
                <Text>
                  Join a community of learners and mentors who are dedicated to your success.
                </Text>
              </VStack>
            </Flex>
            <Flex w="100%" gap="1rem" justify="start">
              <Button
                as="a"
                border="1px solid #46BDFF"
                fontWeight="400"
                color="#46BDFF"
                bg="white"
                _hover={{
                  bg: 'white',
                }}
                aria-label="sign up"
                onClick={() => navigate(Routes.ComingSoon)}
                fontSize={{ base: '14px', md: '16px' }}
              >
                Sign Up
              </Button>
              <Button
                fontWeight="400"
                color="#46BDFF"
                bg="white"
                _hover={{
                  bg: 'white',
                }}
                aria-label="learn more"
                fontSize={{ base: '14px', md: '16px' }}
              >
                Learn More &rarr;
              </Button>
            </Flex>
          </VStack>
          <Box mt={{ base: '1.5rem' }}>
            <Image src={laptop} alt="img" />
          </Box>
        </Flex>
        <Team />
      </Box>
      <Banner4 />
      <BannerTwo />
      <Footer />
    </>
  );
};

export default BannerOne;
