import React from 'react';
import {
  Heading,
  Stack,
  Image,
  Text,
  Card,
  VStack,
  Box,
  Button,
  Link,
  Flex,
} from '@chakra-ui/react';
import { Routes } from '../../constant';
import { useLocation, useNavigate } from 'react-router-dom';
import { ICareerData, ICareerPath, ICareerTeam, ISocialMedia } from '../../types/global';

export const CardComponent = ({ img, title, description, link, path }: ICareerData) => {
  const navigate = useNavigate();
  return (
    <Card maxW="sm" h="450px" borderRadius="lg">
      <VStack>
        <Image width="100%" objectFit="cover" h="200px" src={img} alt="career_img" />
        <Stack px="1rem" mt="6" spacing="3" h="250px">
          <Heading size="md">{title}</Heading>
          <Text>{description}</Text>
          <Text cursor={'pointer'} onClick={() => navigate(path)} color="#FE0459" fontSize="1rem">
            {link} &rarr;
          </Text>
        </Stack>
      </VStack>
    </Card>
  );
};

export const CardCareerPath = ({
  img,
  title,
  description,
  link,
  button,
  index,
  path,
}: ICareerPath) => {
  const navigate = useNavigate();
  return (
    <Box
      maxW="sm"
      py="1rem"
      px="1rem"
      _hover={{
        backgroundColor: index === 0 ? '#ee719a11' : index === 1 ? ' #FECD0521' : '#01F0B612',
        boxShadow:
          index === 0
            ? '0px 0px 10px 0px #fe0457a2'
            : index === 1
            ? '0px 0px 10px 0px #fdd03c97'
            : '0px 0px 10px 0px #01f0b492',
        transition: 'all 0.3s ease',
        cursor: 'pointer',
      }}
      cursor="pointer"
      borderRadius="lg"
    >
      <VStack align="center" justify="center">
        <Image width="50px" objectFit="cover" h="50px" src={img} alt="career_img" />
        <Stack justify="center" textAlign="center" align="center" mt="1" spacing="3" h="250px">
          <Heading size="md">{title}</Heading>
          <Text>{description}</Text>
          {link && (
            <Text
              onClick={() => {
                navigate(path);
              }}
              cursor="pointer"
              color="#FE0459"
              fontSize="1rem"
            >
              {link} &rarr;
            </Text>
          )}
          {button && (
            <Flex justify="center" mt="1rem">
              <Button
                border="1px solid #46BDFF"
                fontWeight="400"
                color="#46BDFF"
                bg="white"
                _hover={{
                  bg: 'white',
                }}
                aria-label="learn more"
                fontSize={{ base: '14px', md: '16px' }}
              >
                {button}
              </Button>
            </Flex>
          )}
        </Stack>
      </VStack>
    </Box>
  );
};

export const CardLearning = ({ img, title, description, link, path }: ICareerPath) => {
  const navigate = useNavigate();
  return (
    <Box
      maxW="sm"
      h="100%"
      borderRadius="lg"
      _hover={{
        backgroundColor: '#01F0B612',
        boxShadow: '0px 0px 10px 0px #323a385b',
        transition: 'all 0.3s ease',
        cursor: 'pointer',
      }}
      px="3rem"
      py="1rem"
    >
      <VStack align="start">
        <Image width="50px" objectFit="cover" h="50px" src={img} alt="career_img" />
        <Stack mt="6" spacing="3" h="250px">
          <Heading size="md">{title}</Heading>
          <Text>{description}</Text>
          <Button
            size="sm"
            width={{ base: '60%', md: '55%' }}
            height={{ base: '40px', md: '40px' }}
            color="#46BDFF"
            fontWeight="400"
            border="1px solid #46BDFF"
            bg="transparent"
            _hover={{
              border: '1.5px solid #46BDFF',
            }}
            onClick={() => {
              navigate(path);
            }}
            aria-label="learn more"
            fontSize={{ base: '14px', md: '16px' }}
          >
            {link} &rarr;{' '}
          </Button>
        </Stack>
      </VStack>
    </Box>
  );
};

export const CardTeam = ({ img, title, subtitle, description, socials }: ICareerTeam) => {
  const location = useLocation();
  const path = location.pathname;
  return (
    <Card
      maxW="sm"
      h={path === Routes.Services || Routes.Testimonials ? '350px' : '480px'}
      borderRadius="lg"
    >
      <VStack
        align={path === Routes.Services || Routes.Testimonials ? 'start' : 'center'}
        justify="center"
      >
        <Box padding={path === Routes.Services || Routes.Testimonials ? '1rem' : ''}>
          <Image
            width={path === Routes.Services || Routes.Testimonials ? '80px' : '100%'}
            borderRadius={path === Routes.Services || Routes.Testimonials ? '50%' : ''}
            objectFit="cover"
            h={path === Routes.Services || Routes.Testimonials ? '80px' : '250px'}
            src={img}
            alt="career_img"
          />
        </Box>
        <Stack
          align={path === Routes.Services || Routes.Testimonials ? 'start' : 'center'}
          px="1rem"
          mt="6"
          spacing="3"
          h="250px"
        >
          <Heading size="sm">{title}</Heading>
          <Heading size="sm">{subtitle}</Heading>
          <Text textAlign={path === Routes.Services || Routes.Testimonials ? 'start' : 'center'}>
            {description}
          </Text>
          <Flex fontSize="1rem">
            {socials?.map((item: ISocialMedia, index: number) => (
              <Link key={index} href={item.link} target="_blank" rel="noopener noreferrer">
                <Image cursor="pointer" src={item.icon} alt="icon" />
              </Link>
            ))}
          </Flex>
        </Stack>
      </VStack>
    </Card>
  );
};
