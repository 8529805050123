import { Box } from '@chakra-ui/react';
import React from 'react';
import ComingSoon from '../../../components/comingsoon';

const CheckIns = () => {
  return (
    <Box h={'90vh'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
      <ComingSoon />
    </Box>
  );
};

export default CheckIns;
