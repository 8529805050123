import { ButtonStyles as Button } from "./buttonColors";


export const customComponents = {
    Button,

}

export type CustomComponentType = typeof customComponents;

export default customComponents;