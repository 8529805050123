import { Box, Button, Flex, Heading, Input, VStack, Text, Image } from '@chakra-ui/react';
import crypto from '../../../../assets/images/crypto.png';
import React from 'react';

const Banner5 = () => {
  return (
    <Box px={{ base: '1.5rem', md: '2rem', lg: '3rem' }} py={{ base: '2rem', md: '4rem' }}>
      <Flex
        width="100%"
        align="center"
        justify="space-between"
        flexDir={{ base: 'column', md: 'column', lg:"row" }}
      >
        <VStack gap="1.5rem" align="start" w={{ base: '100%', md: '85%' }}>
          <Heading lineHeight="1.2" fontSize={{ base: '1.8rem', md: '2.7rem' }}>
            Get Exclusive Career Updates Here{' '}
          </Heading>
          <Text>Subscribe to our newsletter for the latest career advice and special offers.</Text>
          <VStack align="start" width="100%" justify="end">
            <Flex flexDir={{ base: 'column', md: 'column', lg: 'row' }} width="100%" gap=".5rem">
              <Input
                bg="white"
                color="#000"
                width={{ base: '100%', md: '100%', lg: '60%' }}
                type="email"
                placeholder="Enter your email"
              />
              <Button
                bg="#46BDFF"
                fontWeight="400"
                color="white"
                _hover={{
                  bgGradient: 'linear(to-r, #46BDFF, pink.100)',
                  color: 'white',
                  cursor: 'pointer',
                }}
                aria-label="try"
                fontSize={{ base: '14px', md: '16px' }}
              >
                Sign Up
              </Button>
            </Flex>
            <Text fontSize="12px">By clicking Sign Up, you agree to our Terms and Conditions.</Text>
          </VStack>
        </VStack>
        <VStack width="100%" mt={{base:"2rem"}}>
          <Image  src={crypto} alt="img" />
        </VStack>
      </Flex>
    </Box>
  );
};

export default Banner5;
