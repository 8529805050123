const customColor = {
    primary: {
        main: "#46BDFF",
    },
    secondary: {},
    text: {
        primary: '#0F0D0E',
        secondary: '#46BDFF',
        tertiary: "#FE0459"
    },
    bg: {
        main: '#46BDFF',
    },
    success: {},
    warning: {},
    danger: {},
    info: {},
    light: {},
    dark: {},
    white: {},
    black: {},
};

export default customColor;
