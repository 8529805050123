import { Box, Button, Flex, Heading, VStack, Text, Grid } from '@chakra-ui/react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Routes } from '../../../constant';
import { CardCareerPath } from '../../../components/card';
import { careerPath2 } from '../../../data';
import BannerOne from './BannerOne';

const Banners = () => {
  const navigate = useNavigate();

  return (
    <>
      <Box
        w="100%"
        py={{ base: '2rem', md: '4rem' }}
        px={{ base: '1.5rem', md: '2rem', lg: '3rem' }}
      >
        <Flex
          w="100%"
          justify={{ lg: 'space-between' }}
          flexDir={{ base: 'column', md: 'column', lg: 'row' }}
        >
          <VStack align="start" width="50%">
            <Flex
              align="center"
              justify="center"
              borderRadius="7px"
              bg="#01F0B617"
              width="118px"
              height="31px"
            >
              <Text color="#01F0B6">Transform</Text>
            </Flex>

            <Heading lineHeight="1.3" fontSize={{ base: '1.8rem', md: '2.2rem' }}>
              Unlock Your Potential
            </Heading>
          </VStack>

          <VStack width={{ base: '100%', md: '45%' }} gap="1.5rem">
            <Text>
              Get personalized career advice and a tailored learning plan to help you succeed. Our
              AI-powered platform analyzes your inputs and provides expert guidance to guide you
              towards your goals.
            </Text>
            <Flex w="100%" gap="1rem" justify="start">
              <Button
                as="a"
                bg="#46BDFF"
                fontWeight="400"
                cursor="pointer"
                color="white"
                _hover={{
                  bg: '#46BDFF',
                  color: 'white',
                }}
                aria-label="sign up"
                onClick={() => navigate(Routes.ComingSoon)}
                fontSize={{ base: '14px', md: '16px' }}
              >
                Sign Up
              </Button>
              <Button
                border="1px solid #46BDFF"
                fontWeight="400"
                color="#46BDFF"
                bg="white"
                _hover={{
                  bg: 'white',
                }}
                aria-label="learn more"
                fontSize={{ base: '14px', md: '16px' }}
              >
                Learn More
              </Button>
            </Flex>
          </VStack>
        </Flex>
      </Box>
      <VStack
        bg="rgba(253, 209, 60, 0.08)"
        w="100%"
        px={{ base: '1.5rem', md: '2rem', lg: '3rem' }}
        py={{ base: '2rem', md: '4rem' }}
        align="center"
      >
        <Flex
          align="center"
          justify="center"
          borderRadius="7px"
          bg="#FDD13C1C"
          width="118px"
          height="31px"
        >
          <Text color="#FDD13C">Revolutionize</Text>
        </Flex>
        <VStack align="center" width={{ base: '100%', md: '45%' }}>
          <Heading lineHeight="1.3" fontSize={{ base: '1.8rem', md: '2.2rem' }} textAlign="center">
            Personalized Career Advice for Your Success{' '}
          </Heading>
          <Text textAlign="center">
            Discover your true potential with our AI-driven career advice service. Get personalized
            recommendations, AI-driven insights, and expert guidance to help you make informed
            decisions about your future.
          </Text>
        </VStack>
        <Grid
          templateColumns={{ base: '1fr', md: 'repeat(3, 1fr)' }}
          gap={{ base: '2rem', md: '4rem' }}
          mt={{ base: '2rem', md: '4rem' }}
          width="100%"
          mb={{ base: '1rem', md: '1.5rem' }}
        >
          {careerPath2.map((item, index) => (
            <Box key={item.id}>
              <CardCareerPath
                index={index}
                img={item.img}
                title={item.title}
                description={item.description}
                link={''}
              />
            </Box>
          ))}
        </Grid>
        <Flex w="100%" gap="1rem" justify="center">
          <Button
            as="a"
            bg="#46BDFF"
            fontWeight="400"
            cursor="pointer"
            color="white"
            _hover={{
              bg: '#46BDFF',
              color: 'white',
            }}
            aria-label="sign up"
            onClick={() => navigate(Routes.ComingSoon)}
            fontSize={{ base: '14px', md: '16px' }}
          >
            Sign Up
          </Button>
          <Button
            border="1px solid #46BDFF"
            fontWeight="400"
            color="#46BDFF"
            bg="white"
            _hover={{
              bg: 'white',
            }}
            aria-label="learn more"
            fontSize={{ base: '14px', md: '16px' }}
          >
            Learn More
          </Button>
        </Flex>
      </VStack>
      <BannerOne />
    </>
  );
};

export default Banners;
