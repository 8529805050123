import { ICareerData, ICareerPath } from '../types/global';
import career1 from '../assets/images/career1.png';
import career2 from '../assets/images/career2.png';
import career3 from '../assets/images/career3.png';
import graduate from '../assets/icons/graduate.svg';
import easy from '../assets/icons/easy.svg';
import dynamic from '../assets/icons/dynamic.svg';
import goal from '../assets/icons/goal.svg';
import learning from '../assets/icons/learning.svg';
import path from '../assets/icons/path.svg';
import book from '../assets/icons/book.svg';
import linkedin from '../assets/icons/linkedin.svg';
import email from '../assets/icons/email.svg';
// import phone from '../assets/icons/phone.svg';
import location from '../assets/icons/location.svg';
import twitter from '../assets/icons/twitter.svg';
import dribble from '../assets/icons/dribble.svg';
import person1 from '../assets/images/person1.png';
import person2 from '../assets/images/person2.png';
import person3 from '../assets/images/person3.png';
import person4 from '../assets/images/person4.png';
import person5 from '../assets/images/person5.png';
import person7 from '../assets/images/person6.png';
import person6 from '../assets/images/person7.png';
import person8 from '../assets/images/people.png';
import { Routes } from '../constant';

export const careerData = [
  {
    id: '1',
    img: career1,
    title: 'Get Personalized Career Advice and Learning Plans',
    description:
      'Our intuitive interface makes it simple for students to access and utilize our AI-powered features.',
    link: 'Learn More',
    path: Routes.Discover,
  },
  {
    id: '2',
    img: career2,
    title: 'Personalized Career Advice',
    description:
      "Our AI algorithms provide personalized career advice based on each student's strengths and interests.",
    link: 'Get Started',
    path: Routes.ComingSoon,
  },
  {
    id: '3',
    img: career3,
    title: 'Tailored Learning Plans',
    description:
      "We create customized learning plans based on each student's unique goals and interests.",
    link: 'Sign Up',
    path: Routes.ComingSoon,
  },
] as ICareerData[];

export const careerPath = [
  {
    id: '1',
    img: graduate,
    title: 'Create Your Customized Learning Plan for Success',
    description:
      'Our AI-powered platform analyzes your interests, skills, and goals to provide personalized career advice and recommend a learning plan tailored to your needs.',
    link: 'Learn More',
    path: Routes.Programs,
  },
  {
    id: '2',
    img: easy,
    title: 'Explore a Wide Range of Career Options and Educational Paths',
    description:
      'With our advanced algorithms, we match your profile with various career options and educational paths, helping you make informed decisions.',
    link: 'Get Started',
    path: Routes.ComingSoon,
  },
  {
    id: '3',
    img: dynamic,
    title: 'Stay on Track with Personalized Progress Monitoring',
    description:
      'Our platform tracks your learning progress, provides feedback, and suggests adjustments to keep you on the path to success.',
    link: 'Sign Up',
    path: Routes.ComingSoon,
  },
] as ICareerPath[];

export const careerPath2 = [
  {
    id: '1',
    img: graduate,
    title: 'Personalized Recommendations Just for You',
    description:
      'Our AI technology analyzes your unique profile to provide personalized career recommendations tailored to your interests, skills, and goals.',
  },
  {
    id: '2',
    img: easy,
    title: 'Expert Guidance Every Step of the Way',
    description:
      'Our team of career experts is here to provide personalized guidance and support, helping you navigate your career journey with confidence.',
  },
  {
    id: '3',
    img: dynamic,
    title: 'AI-Driven Insights for Career Growth',
    description:
      'Leverage the power of AI to gain valuable insights into the job market, industry trends, and skills in demand to stay ahead in your career.',
  },
] as ICareerPath[];
export const learningPath = [
  {
    id: '1',
    img: goal,
    title: 'Achieve Your Educational Goals',
    description: 'Receive guidance and support to excel in your academic journey.',
    link: 'Learn More',
    path: Routes.Programs,
  },
  {
    id: '2',
    img: path,
    title: 'Discover Your Ideal Career Path',
    description:
      'Get personalized career advice and recommendations based on your interests and goals.',
    link: 'Learn More',
    path: Routes.Discover,
  },
  {
    id: '3',
    img: learning,
    title: 'Create Your Personalized Learning Plan',
    description: 'Build a customized learning plan tailored to your needs and aspirations.',
    link: 'Learn More',
    path: Routes.Programs,
  },
];

export const teamData = [
  {
    id: 1,
    img: person1,
    title: 'John Doe',
    subtitle: 'Designer',
    description: 'Passionate designer with a love for creativity.',
    socials: [
      {
        icon: linkedin,
        link: '',
      },
      {
        icon: twitter,
        link: '',
      },
      {
        icon: dribble,
        link: '',
      },
    ],
  },
  {
    id: 2,
    img: person2,
    title: 'Jane Smith',
    subtitle: 'Developer',
    description: 'Full-stack developer with a passion for coding.',
    socials: [
      {
        icon: linkedin,
        link: '',
      },
      {
        icon: twitter,
        link: '',
      },
      {
        icon: dribble,
        link: '',
      },
    ],
  },

  {
    id: 3,
    img: person3,
    title: 'Alice Johnson',
    subtitle: 'Marketing Manager',
    description: 'Experienced marketing manager with a focus on branding.',
    socials: [
      {
        icon: linkedin,
        link: '',
      },
      {
        icon: twitter,
        link: '',
      },
      {
        icon: dribble,
        link: '',
      },
    ],
  },
  {
    id: 4,
    img: person4,
    title: 'Bob Brown',
    subtitle: 'Software Engineer',
    description: 'Software engineer with a passion for open-source projects.',
    socials: [
      {
        icon: linkedin,
        link: '',
      },
      {
        icon: twitter,
        link: '',
      },
      {
        icon: dribble,
        link: '',
      },
    ],
  },
  {
    id: 5,
    img: person5,
    title: 'Eva Davis',
    subtitle: 'Content Writer',
    description: 'Creative content writer with a love for storytelling.',
    socials: [
      {
        icon: linkedin,
        link: '',
      },
      {
        icon: twitter,
        link: '',
      },
      {
        icon: dribble,
        link: '',
      },
    ],
  },
  {
    id: 6,
    img: person6,
    title: 'Mike Wilson',
    subtitle: 'Product Manager',
    description: 'Experienced product manager with a focus on user experience.',
    socials: [
      {
        icon: linkedin,
        link: '',
      },
      {
        icon: twitter,
        link: '',
      },
      {
        icon: dribble,
        link: '',
      },
    ],
  },
  {
    id: 7,
    img: person7,
    title: 'Linda Adams',
    subtitle: 'Graphic Designer',
    description: 'Graphic designer with a passion for visual storytelling.',
    socials: [
      {
        icon: linkedin,
        link: '',
      },
      {
        icon: twitter,
        link: '',
      },
      {
        icon: dribble,
        link: '',
      },
    ],
  },
  {
    id: 8,
    img: person8,
    title: 'Samuel Lee',
    subtitle: 'Data Scientist',
    description: 'Data scientist with expertise in machine learning and AI.',
    socials: [
      {
        icon: linkedin,
        link: '',
      },
      {
        icon: twitter,
        link: '',
      },
      {
        icon: dribble,
        link: '',
      },
    ],
  },
];

export const careerPath3 = [
  {
    id: '1',
    img: book,
    title: 'Access Learning Resources Anytime, Anywhere',
    description:
      'Enjoy convenient access to a wide range of learning resources whenever and wherever you need them.',
    button: 'Learn More',
  },
  {
    id: '2',
    img: dynamic,
    title: 'Stay on Track with Progress Tracking',
    description:
      "Track your progress and see how far you've come with our easy-to-use progress tracking feature.",
    button: 'Learn More',
  },
  {
    id: '3',
    img: easy,
    title: 'Customized Learning Plans for Your Success',
    description:
      'Our Learning Plan service offers personalized course suggestions, progress tracking, and easy access to learning resources.',
    button: 'Learn More',
  },
] as ICareerPath[];

export const faqs = [
  {
    title: 'Can I change my inputs?',
    content: 'Yes',
  },
  {
    title: 'How accurate is it?',
    content: 'Very accurate',
  },
  {
    title: 'How does it work?',
    content: 'Efficiently',
  },
  {
    title: 'Is it free?',
    content: 'No',
  },
  {
    title: 'Who can use it? ',
    content: 'Any user',
  },
];

export const socials = [
  {
    icon: email,
    text: 'sam@skilltrak.ai',
  },
  // {
  //   icon: phone,
  //   text: '+1 (555) 123-4567',
  // },
  {
    icon: location,
    text: 'The Business Development Lab, WPI - 50, Prescott Street, Worcester, MA 01605',
  },
];

export const fields = [
  {
    placeholder: 'Skill Set',
    id: 'skillSet',
  },
  {
    placeholder: 'Projects',
    id: 'projects',
  },
  {
    placeholder: 'Previous Work',
    id: 'previousWork',
  },
  {
    placeholder: 'Role',
    id: 'role',
  },
  {
    placeholder: 'Company',
    id: 'company',
  },
  {
    placeholder: 'University',
    id: 'university',
  },
  {
    placeholder: 'Major',
    id: 'major',
  },
  {
    placeholder: 'Course',
    id: 'course',
  },
];

export const accountTypeOption = [
  {
    label: 'Student',
    value: 'Student',
  },
  {
    label: 'Mentor',
    value: 'Mentor',
  },
  {
    label: 'Institution',
    value: 'Institution',
  },
] as any;

export const gender = [
  {
    label: 'Male',
    value: 'Male',
  },
  {
    label: 'Female',
    value: 'Female',
  },
  {
    label: 'Other',
    value: 'Other',
  },
] as any;

export const educationlevel = [
  {
    label: 'Diploma',
    value: 'Diploma',
  },
  {
    label: 'Bachelor',
    value: 'Bachelor',
  },
  {
    label: 'Master',
    value: 'Master',
  },
  {
    label: 'Doctorate',
    value: 'Doctorate',
  },
] as any;
