import { Flex, VStack, Heading, HStack, Button, Text } from '@chakra-ui/react';
import React from 'react'

const Career = ({data, index}:any) => {
  return (
    <Flex
      key={index}
      w="100%"
      flexDir={{ base: 'column', md: 'row' }}
      justify="space-between"
      gap={{ base: '1rem' }}
    >
      <VStack align="start">
        <Heading lineHeight="1.2" fontSize={{ base: '1.8rem', md: '2.6rem' }}>
          {data.title}{' '}
        </Heading>
        <Text>{data.subtitle}</Text>
      </VStack>
      <HStack>
        <Button
          as="a"
          bg="#46BDFF"
          cursor="pointer"
          _hover={{
            bgGradient: 'linear(to-r, #46BDFF, pink.100)',
            color: 'white',
          }}
          fontWeight="400"
          border="1px solid #46BDFF"
          color="white"
          aria-label="try"
          fontSize={{ base: '14px', md: '16px' }}
        >
          {data.button1}{' '}
        </Button>
        <Button
          border="1px solid #46BDFF"
          bg="none"
          color="#46BDFF"
          fontWeight="400"
          _hover={{
            bg: '#46beff19',
          }}
          aria-label="learn more"
          fontSize={{ base: '14px', md: '16px' }}
        >
          {data.button2}
        </Button>
      </HStack>
    </Flex>
  );
}

export default Career
