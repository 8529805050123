import {
  Box,
  VStack,
  Text,
  Flex,
  Button,
  FormControl,
  Heading,
  Input,
  Highlight,
  HStack,
  FormErrorMessage,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Routes } from '../../../constant';
import { useDispatch, useSelector } from 'react-redux';
import { verifyUser } from '../../../store/Auth';
import { Formik, Form, Field } from 'formik';
import { ThunkDispatch } from 'redux-thunk';

import * as Yup from 'yup';
import { AnyAction } from '@reduxjs/toolkit';
import { RootState } from '../../../store';
import toast from 'react-hot-toast';

const validationSchema = Yup.object().shape({
  verificationCode: Yup.string()
    .test('notEmpty', 'Verification code is required', function (value: any) {
      return value && value.trim().length > 0;
    })
    .matches(/^\d{5}$/, 'Code must be a 5-digit number')
    .required('Verification code is required'),
});

const Verify = () => {
  const dispatch: ThunkDispatch<RootState, any, AnyAction> = useDispatch();
  const navigate = useNavigate(); // Get the navigate function
  const [otp, setOtpCode] = useState<string>('');
  const { isLoading, isSuccess, isError, message } = useSelector((state: any) => state.auth);

  useEffect(() => {
    if (isError) {
      toast.error(message);
      // navigate(Routes.Verify);
    } else if (isSuccess) {
      navigate(Routes.Login);
    }
  }, [isError, isSuccess, message]);
  const handleVerification = async () => {
    await dispatch(verifyUser({ otp: otp }));
  };
  const handleGoBack = () => {
    navigate(Routes.Signup);
  };
  return (
    <Box w="100%" h="100%" pos="relative">
      <VStack align="center" py="2rem" w="100%" h="100%">
        <Flex pos="absolute" mt="-1rem" left={{ base: '0%', lg: '-10%', xl: '-15%' }} w="100%">
          <Text cursor="pointer" onClick={handleGoBack}>
            {' '}
            &larr; Back
          </Text>
        </Flex>

        <VStack mt="2rem" w="100%" h="100%" gap="2rem">
          <Heading fontSize={{ base: '3xl', md: '3xl' }}>Verify Your Email</Heading>
          <Text textAlign="center" my={{ base: 2, md: 4 }}>
            A 5 digit code was sent to your registered mail
          </Text>
          <Formik
            initialValues={{ otp: '' }}
            validationSchema={validationSchema}
            onSubmit={handleVerification}
          >
            {({ errors, touched, handleChange, handleBlur }) => (
              <Form>
                <Box w="100%" p="0rem 1rem" h="100%">
                  <Flex w="100%" gap="1rem">
                    {[...Array(5)].map((_, index) => (
                      <FormControl key={index} isInvalid={errors.otp && touched.otp ? true : false}>
                        {/* <Field
                          name="verificationCode"
                          render={({
                            field,
                          }: {
                            field: { onChange: any; onBlur: any; value: string };
                          }) => (
                            <Input
                              {...field}
                              background="#F8F8F8"
                              h="70px"
                              fontSize={{ base: '1rem', md: '0.875rem' }}
                              type="number"
                              value={otp[index] || ''}
                              onChange={(e) => {
                                const updatedCode = Array.from(otp);
                                updatedCode[index] = e.target.value;
                                setOtpCode(updatedCode.join(''));
                                handleChange(e);
                              }}
                              onBlur={handleBlur}
                            />
                          )}
                        /> */}
                        <Field name="verificationCode">
                          {({ field }: any) => (
                            <Input
                              {...field}
                              background="#F8F8F8"
                              h="70px"
                              fontSize={{ base: '1rem', md: '0.875rem' }}
                              type="number"
                              value={otp[index] || ''}
                              onChange={(e) => {
                                const updatedCode = Array.from(otp);
                                updatedCode[index] = e.target.value;
                                setOtpCode(updatedCode.join(''));
                                handleChange(e);
                              }}
                              onBlur={handleBlur}
                            />
                          )}
                        </Field>

                        <FormErrorMessage>
                          {errors?.otp && touched.otp ? errors.otp : null}
                        </FormErrorMessage>
                      </FormControl>
                    ))}
                  </Flex>

                  <HStack align="center" justify="center" mt="2rem">
                    <Highlight
                      query={'Resend code'}
                      styles={{ color: '#46BDFF', cursor: 'pointer' }}
                    >
                      Didn’t get a code? Resend code
                    </Highlight>
                  </HStack>
                  <Button
                    mt={{ base: '1rem', md: '2rem' }}
                    cursor="pointer"
                    w="100%"
                    h="55px"
                    bg="#46BDFF"
                    fontWeight="400"
                    color="white"
                    _hover={{
                      bgGradient: 'linear(to-r, #46BDFF, pink.100)',
                      color: 'white',
                    }}
                    disabled={errors.otp || isLoading}
                    size="md"
                    type="submit"
                    isLoading={isLoading}
                    aria-label="verify account"
                    onClick={handleVerification}
                  >
                    Verify{' '}
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </VStack>
      </VStack>
    </Box>
  );
};

export default Verify;
