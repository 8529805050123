import { VStack, Button } from '@chakra-ui/react';
import React from 'react'
import { AiFillApple } from 'react-icons/ai';
import { BsFacebook } from 'react-icons/bs';
import { FcGoogle } from 'react-icons/fc';

const SocialLogin = () => {
  return (
    <VStack align="start" spacing="1rem" w="100%">
      <Button
        border="1px solid rgba(13, 13, 13, 0.30)"
        size="md"
        aria-label="Sign In with google"
        cursor="pointer"
        w="100%"
        h="55px"
        bg="none"
      >
        <FcGoogle size={25} />{' '}
        <span style={{ marginLeft: '.5rem', fontWeight: '400' }}>Sign in with Google</span>
      </Button>
      <Button
        border="1px solid rgba(13, 13, 13, 0.30)"
        size="md"
        aria-label="Sign In with google"
        cursor="pointer"
        w="100%"
        h="55px"
        bg="none"
      >
        <BsFacebook size={20} />
        <span style={{ marginLeft: '.5rem', fontWeight: '400' }}>Sign in with Facebook</span>
      </Button>
      <Button
        border="1px solid rgba(13, 13, 13, 0.30)"
        size="md"
        aria-label="Sign In with google"
        cursor="pointer"
        w="100%"
        h="55px"
        bg="none"
      >
        <AiFillApple size={25} />{' '}
        <span style={{ marginLeft: '.5rem', fontWeight: '400' }}>Sign in with Apple</span>
      </Button>
    </VStack>
  );
}

export default SocialLogin
