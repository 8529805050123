import { combineReducers, configureStore } from '@reduxjs/toolkit';
import authReducer from './Auth';
import AIResponse from './AIResponse';

export const store = configureStore({
  reducer: combineReducers({
    auth: authReducer,
    AIResponse: AIResponse,
  }),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
