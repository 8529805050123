import { Box, Flex, VStack, Text, Input, Button } from '@chakra-ui/react';
import React from 'react';
import Footer from '../../../../components/footer';

const Banner8 = () => {
  return (
    <>
 
    <Box
      bg="#0F0D0E"
      color="white"
      width="100%"
      px={{ base: '1.5rem', md: '2rem', lg: '3rem' }}
      py={{ base: '2rem', md: '4rem' }}
    >
      <Flex
        flexDir={{ base: 'column', md: 'column', lg: 'row' }}
        align="center"
        width="100%"
        justify="space-between"
        gap={{ base: '1rem' }}
      >
        <VStack align="start" width="100%">
          <Text fontWeight="700" fontSize={{ base: '1.5rem', md: '2rem' }}>
            Stay Updated with Our Newsletter
          </Text>
          <Text fontSize="16px">Get the latest updates and special offers.</Text>
        </VStack>
        <VStack width="100%" justify="end">
          <Flex
            flexDir={{ base: 'column', md: 'column', lg: 'row' }}
            width="100%"
            gap=".5rem"
            justify="end"
          >
            <Input
              bg="white"
              color="#000"
              width={{ base: '100%', md: '100%', lg: '60%' }}
              type="email"
              placeholder="Enter your email"
            />
            <Button
              bg="#46BDFF"
              fontWeight="400"
              color="white"
              _hover={{
                bgGradient: 'linear(to-r, #46BDFF, pink.100)',
                color: 'white',
                cursor: 'pointer',
              }}
              aria-label="try"
              fontSize={{ base: '14px', md: '16px' }}
            >
              Sign Up
            </Button>
          </Flex>
          <Text fontSize="12px">By clicking Sign Up, you agree to our Terms and Conditions.</Text>
        </VStack>
      </Flex>
    </Box>
      <Footer />
       </>
  );
};

export default Banner8;
