import {
  Box,
  VStack,
  Heading,
  Text,
  Button,
  Divider,
  Link,
  Flex,
  FormControl,
  Input,
  SimpleGrid,
  FormErrorMessage,
  IconButton,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import SocialLogin from '../social_login';
import { NavLink, useNavigate } from 'react-router-dom';
import { Routes } from '../../../constant';
import Select from 'react-select';
import { registerUser, reset } from '../../../store/Auth';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form } from 'formik';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import toast from 'react-hot-toast';
import Loader from '../../../components/loader';
import { validationSchema } from '../../../utils';
import { accountTypeOption } from '../../../data';
import { ThunkDispatch, AnyAction } from '@reduxjs/toolkit';
import { RootState } from '../../../store';

const SignUp = () => {
  const navigate = useNavigate();
  const dispatch: ThunkDispatch<RootState, any, AnyAction> = useDispatch();
  const [showSignUpForm, setShowSignUpForm] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const formData = {
    firstName: '',
    lastName: '',
    password: '',
    email: '',
    phoneNumber: '',
    location: '',
    accountType: '',
  };

  const { user, isLoading, isSuccess, isError, message } = useSelector((state: any) => state.auth);

  useEffect(() => {
    if (isError) {
      toast.error(message);
    }
    if (isSuccess) {
      navigate(Routes.Verify);
    }

    if (user?.data?.status === 401) {
      navigate(Routes.Signup);
    }
    dispatch(reset());
  }, [user, isLoading, isSuccess, isError, message, dispatch]);

  const handleGoBack = () => {
    navigate(Routes.Signup); // Navigate back one step
    setShowSignUpForm(!showSignUpForm);
  };
  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };
  const handleShowSignUpForm = () => {
    setShowSignUpForm(!showSignUpForm);
  };
  if (isLoading) {
    <Loader />;
  }

  const handleSubmit = async (values: any) => {
    values.preventDefault;
    const userData = {
      firstName: values.firstName,
      lastName: values.lastName,
      password: values.password,
      email: values.email,
      phoneNumber: values.phoneNumber,
      location: values.location,
      accountType: values.accountType,
    };
    await dispatch(registerUser(userData));
  };

  return (
    <Box w="100%" h="100%" pos="relative">
      <VStack align="center" py="2rem" w="100%" h="100%">
        {showSignUpForm ? (
          <Box w="100%">
            <Flex pos="absolute" mt="-1rem" left={{ base: '0%', lg: '-10%', xl: '-15%' }} w="100%">
              <Text cursor="pointer" onClick={handleGoBack}>
                {' '}
                &larr; Back
              </Text>
            </Flex>

            <VStack mt="2rem" w="100%" h="100%">
              <Heading fontSize={{ base: '3xl', md: '3xl' }}>Sign Up With Email</Heading>
              <Text my={{ base: 2, md: 2 }}>Enter your details to create your account</Text>
              <Formik
                initialValues={formData}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ values, errors, touched, handleChange, handleBlur, setFieldValue }) => (
                  <Form style={{ width: '100%' }}>
                    <Box w="100%" p="0rem 1rem" h="100%">
                      <SimpleGrid spacing={1} columns={{ base: 1, md: 2 }} w="100%">
                        <FormControl
                          my={{ base: 2, md: 2 }}
                          isInvalid={errors.firstName && touched.firstName ? true : false}
                        >
                          <Input
                            background="#F8F8F8"
                            h="55px"
                            id="firstName"
                            name="firstName"
                            value={values.firstName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            type="text"
                            placeholder="First Name"
                            autoFocus
                            fontSize={{ base: '1rem', md: '0.875rem' }}
                            aria-label="first name"
                          />
                          <FormErrorMessage>
                            {errors?.firstName && touched.firstName ? errors.firstName : null}
                          </FormErrorMessage>
                        </FormControl>
                        <FormControl
                          my={{ base: 2, md: 2 }}
                          isInvalid={errors.lastName && touched.lastName ? true : false}
                        >
                          <Input
                            background="#F8F8F8"
                            h="55px"
                            id="lastName"
                            name="lastName"
                            value={values.lastName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            fontSize={{ base: '1rem', md: '0.875rem' }}
                            type="text"
                            autoFocus
                            placeholder="Last Name"
                            aria-label="last name"
                          />
                          <FormErrorMessage>
                            {errors?.lastName && touched.lastName ? errors.lastName : null}
                          </FormErrorMessage>
                        </FormControl>
                      </SimpleGrid>
                      <SimpleGrid columns={{ base: 1, md: 1 }} w="100%">
                        <FormControl
                          my={{ base: 2, md: 2 }}
                          isInvalid={errors.email && touched.email ? true : false}
                        >
                          <Input
                            background="#F8F8F8"
                            h="55px"
                            id="email"
                            name="email"
                            type="email"
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder="Email"
                            fontSize={{ base: '1rem', md: '0.875rem' }}
                            aria-label="Email"
                          />
                          <FormErrorMessage>
                            {errors?.email && touched.email ? errors.email : null}
                          </FormErrorMessage>
                        </FormControl>
                        <FormControl
                          my={{ base: 2, md: 2 }}
                          isInvalid={errors.password && touched.password ? true : false}
                        >
                          <InputGroup>
                            <Input
                              background="#F8F8F8"
                              h="55px"
                              id="password"
                              name="password"
                              type={showPassword ? 'text' : 'password'}
                              placeholder="Enter your password"
                              value={values.password}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              fontSize={{ base: '1rem', md: '0.875rem' }}
                              aria-label="Password"
                            />
                            <InputRightElement mt=".7rem">
                              <IconButton
                                variant="ghost"
                                _hover={{ bg: 'transparent' }}
                                aria-label={showPassword ? 'Hide password' : 'Show password'}
                                icon={showPassword ? <ViewOffIcon /> : <ViewIcon />}
                                onClick={handleTogglePassword}
                              />
                            </InputRightElement>
                          </InputGroup>

                          <FormErrorMessage>
                            {errors?.password && touched.password ? errors.password : null}
                          </FormErrorMessage>
                        </FormControl>

                        <FormControl
                          my={{ base: 2, md: 2 }}
                          isInvalid={errors.phoneNumber && touched.phoneNumber ? true : false}
                        >
                          <Input
                            background="#F8F8F8"
                            h="55px"
                            id="phoneNumber"
                            name="phoneNumber"
                            value={values.phoneNumber}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            type="tel"
                            placeholder="Phone Number"
                            autoFocus
                            fontSize={{ base: '1rem', md: '0.875rem' }}
                            aria-label="phone number"
                          />
                          <FormErrorMessage>
                            {errors?.phoneNumber && touched.phoneNumber ? errors.phoneNumber : null}
                          </FormErrorMessage>
                        </FormControl>
                        <FormControl
                          my={{ base: 2, md: 2 }}
                          isInvalid={errors.location && touched.location ? true : false}
                        >
                          <Input
                            background="#F8F8F8"
                            h="55px"
                            id="location"
                            name="location"
                            value={values.location}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            type="text"
                            placeholder="Location"
                            autoFocus
                            fontSize={{ base: '1rem', md: '0.875rem' }}
                            aria-label="location"
                          />
                          <FormErrorMessage>
                            {errors?.location && touched.location ? errors.location : null}
                          </FormErrorMessage>
                        </FormControl>
                        <FormControl
                          my={{ base: 2, md: 2 }}
                          isInvalid={errors.accountType && touched.accountType ? true : false}
                        >
                          <Select
                            id="accountType"
                            name="accountType"
                            options={accountTypeOption}
                            value={
                              accountTypeOption.find(
                                (option: any) => option.value === values.accountType,
                              ) || ''
                            }
                            onChange={(selectedOption: any) =>
                              setFieldValue('accountType', selectedOption.value)
                            }
                            onBlur={handleBlur}
                            placeholder="Select an account type"
                            styles={{
                              control: (base) => ({
                                ...base,
                                height: '55px',
                                fontSize: '1rem',
                                background: '#F8F8F8',
                                border: '.5px solid rgba(13, 13, 13, 0.159)',
                                borderRadius: '8px',
                                boxShadow: 'none',
                                '&:hover': {
                                  border: '1px solid rgba(13, 13, 13, 0.30)',
                                },
                              }),
                              placeholder: (base) => ({
                                ...base,
                                fontSize: '1rem',
                              }),
                            }}
                          />
                          <FormErrorMessage>
                            {errors?.accountType && touched.accountType ? errors.accountType : null}
                          </FormErrorMessage>
                        </FormControl>
                      </SimpleGrid>
                      <Button
                        mt={{ base: '1rem' }}
                        cursor="pointer"
                        w="100%"
                        h="55px"
                        bg="#46BDFF"
                        fontWeight="400"
                        color="white"
                        _hover={{
                          bgGradient: 'linear(to-r, #46BDFF, pink.100)',
                          color: 'white',
                        }}
                        size="md"
                        disabled={
                          errors.firstName ||
                          errors.lastName ||
                          errors.email ||
                          errors.phoneNumber ||
                          errors.location ||
                          errors.accountType ||
                          isLoading
                        }
                        isLoading={isLoading}
                        type="submit"
                        aria-label="create account"
                      >
                        Create Account
                      </Button>
                    </Box>
                  </Form>
                )}
              </Formik>
            </VStack>
          </Box>
        ) : (
          <Box w="100%">
            <Heading fontSize={{ base: '3xl', md: '3xl' }} textAlign="center">
              Create An Account
            </Heading>
            <Text textAlign="center" fontSize={{ base: 'sm', md: 'lg' }} my={{ base: 2, md: 6 }}>
              Get started by creating an account in less than 2 minutes
            </Text>
            <Button
              cursor="pointer"
              w="100%"
              h="55px"
              bg="#46BDFF"
              fontWeight="400"
              color="white"
              _hover={{
                bgGradient: 'linear(to-r, #46BDFF, pink.100)',
                color: 'white',
              }}
              size="md"
              type="submit"
              aria-label="Log In"
              onClick={handleShowSignUpForm}
            >
              Sign up with Email{' '}
            </Button>
            <Divider my="2rem" />
            <Box w="100%">
              <SocialLogin />
            </Box>
            <Text my={4} fontSize="15px" align="center">
              Already have an account?{' '}
              <Link as={NavLink} to={Routes.Login} color="#46BDFF" aria-label="sign in">
                Sign Up
              </Link>
            </Text>
          </Box>
        )}
      </VStack>
    </Box>
  );
};

export default SignUp;
