export const TOKEN = '_TKN_USR';
export const ENCRYPTION_KEY = 'encry-tks-usr';

export const Routes = {
  Home: '/',
  ComingSoon: '/coming-soon',
  Login: '/auth/login',
  Signup: '/auth/signup',
  Verify: '/auth/verify',
  Discover: '/discover',
  AboutUs: '/about-us',
  Services: '/services',
  Resources: '/resources',
  Testimonials: '/testimonials',
  Programs: '/programs',
  ContactUs: '/contact-us',
  Logout: '/auth/logout',
  Dashboard: '/dashboard',
  DashboardSidebar: '/dashboard/sidebar',
  DashboardHome: '/dashboard/home',
  DashboardCheckIn: '/dashboard/checkin',
  DashboardFeedbacks: '/dashboard/feedbacks',
  DashboardLearning: '/dashboard/learning',
};
